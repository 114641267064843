/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignEventMaterialsRequest } from '../models/AssignEventMaterialsRequest';
import type { CreateEventRequest } from '../models/CreateEventRequest';
import type { EventDetailedResponse } from '../models/EventDetailedResponse';
import type { UpdateEventRequest } from '../models/UpdateEventRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class EventsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new event
     * @param requestBody 
     * @returns EventDetailedResponse Created
     * @throws ApiError
     */
    public createEvent(
requestBody?: CreateEventRequest,
): CancelablePromise<EventDetailedResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Events',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get events
     * @returns EventDetailedResponse Success
     * @throws ApiError
     */
    public getEvents(): CancelablePromise<Array<EventDetailedResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Events',
        });
    }

    /**
     * Update an existing event
     * @param id 
     * @param requestBody 
     * @returns EventDetailedResponse Created
     * @throws ApiError
     */
    public updateEvent(
id: number,
requestBody?: UpdateEventRequest,
): CancelablePromise<EventDetailedResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Events/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>30101</i> - The event doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Get event with details
     * @param id 
     * @returns EventDetailedResponse Success
     * @throws ApiError
     */
    public getEvent(
id: number,
): CancelablePromise<EventDetailedResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Events/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>30101</i> - The event doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Delete event. Related material will not be deleted
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public deleteEvent(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Events/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>30101</i> - The event doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Assign materials to an event.
     * This will replace any existing materials for the event.
     * @param id 
     * @param requestBody 
     * @returns EventDetailedResponse Success
     * @throws ApiError
     */
    public assignEventMaterials(
id: number,
requestBody?: AssignEventMaterialsRequest,
): CancelablePromise<EventDetailedResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Events/{id}/assignMaterials',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>30101</i> - The event doesn't exist</li></ul>`,
            },
        });
    }

}