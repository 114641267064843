import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { createCCMindApiClient } from '../api/ApiClientFactory';
import { ContentResponse } from '../api/generated';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { handleRejected, handlePending, StatusSliceBase, genericApiErrorMessage, initialStateBase, handleSuccess } from './sliceHelper';
import { RootState } from './store';


export interface ContentsState {
  Contents: ContentResponse[];
  Content: ContentResponse | null;

  GetContentsRequest: StatusSliceBase;
  UpdateContentRequest: StatusSliceBase;
}
const initialState: ContentsState = {
  Contents: [],
  Content: null,

  GetContentsRequest: { ...initialStateBase },
  UpdateContentRequest: { ...initialStateBase },
};

export const GetContentsAsync = createAsyncThunk(
  'contents/getContents',
  async (_, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().contents.getContents()
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  },
  { condition: (_, { getState }) => (getState() as RootState).contents.GetContentsRequest.status !== "loading" });

export const UpdateContentAsync = createAsyncThunk(
  'contents/updateContent',
  async (obj: { id: number, content: string, contentEn?: string }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().contents.updateContent(obj.id, { content: obj.content, contentEn: obj.contentEn })
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const ContentsSlice = createSlice({
  name: 'Contents',
  initialState,
  reducers: {
    clearDetailedContent: (state) => {
      state.Content = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(GetContentsAsync.pending, (state) => handlePending(state.GetContentsRequest));
    builder.addCase(GetContentsAsync.rejected, (state, { payload }) => handleRejected(state.GetContentsRequest, payload as string));
    builder.addCase(GetContentsAsync.fulfilled, (state, action) => {
      handleSuccess(state.GetContentsRequest);
      state.Contents = action.payload;
    });

    builder.addCase(UpdateContentAsync.pending, (state) => handlePending(state.UpdateContentRequest));
    builder.addCase(UpdateContentAsync.rejected, (state, { payload }) => handleRejected(state.UpdateContentRequest, payload as string));
    builder.addCase(UpdateContentAsync.fulfilled, (state, action) => {
      handleSuccess(state.UpdateContentRequest);
      state.Contents = state.Contents.map(g => g.id === action.payload.id ? action.payload : g);
    });
  },
});

export const { clearDetailedContent } = ContentsSlice.actions;

export const selectContentById = (id: number | null) => (state: RootState) => {
  if (id === null)
    return null;
  return state.contents.Contents.find(g => g.id === id) || null;
};

export const selectContentsState = (state: RootState) => {
  return {
    contents: state.contents.Contents,
    ...state.contents.GetContentsRequest
  }
};

export const selectUpdateContentState = (state: RootState) => state.contents.UpdateContentRequest;
export const useContents = () => {
  var state = useAppSelector(selectContentsState);
  var dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetContentsAsync());
  }, [dispatch])

  return state
}

export default ContentsSlice.reducer;