/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContentResponse } from '../models/ContentResponse';
import type { CreateContentRequest } from '../models/CreateContentRequest';
import type { UpdateContentRequest } from '../models/UpdateContentRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class ContentsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new content
     * @param requestBody 
     * @returns ContentResponse Created
     * @throws ApiError
     */
    public createContent(
requestBody?: CreateContentRequest,
): CancelablePromise<ContentResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Contents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get contents
     * @returns ContentResponse Success
     * @throws ApiError
     */
    public getContents(): CancelablePromise<Array<ContentResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Contents',
        });
    }

    /**
     * Update an existing content
     * @param id 
     * @param requestBody 
     * @returns ContentResponse Created
     * @throws ApiError
     */
    public updateContent(
id: number,
requestBody?: UpdateContentRequest,
): CancelablePromise<ContentResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Contents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>60101</i> - The content doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Delete content.
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public deleteContent(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Contents/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>60101</i> - The content doesn't exist</li></ul>`,
            },
        });
    }

}