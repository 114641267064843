import {
  Container,
  Divider,
  Heading,
  Stack,
} from '@chakra-ui/react'
import styles from './privacyPolicy.module.scss'

const PrivacyPolicy = () => {
  return (
    <Container paddingTop="150px" maxW={'100ch'}>
      <Stack spacing={10}>
        <Stack spacing={4}>
          <Heading as="h1" size="lg">
            C&C Mind Privacy Policy
          </Heading>
          <Divider />
          <Container maxW={'full'} className={styles.privacyPolicy}>
            <a
              className="wpembed-inline"
              href="https://app.websitepolicies.com/policies/view/2yi132fx"
            >
              Privacy policy
            </a>
          </Container>
        </Stack>
        {/* <PrivacyPolicyForm></PrivacyPolicyForm> */}
      </Stack>
    </Container>
  )
}

export default PrivacyPolicy
