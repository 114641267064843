import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createCCMindApiClient } from '../api/ApiClientFactory';
import { AccountResponse, ApiError, ApiErrorResponse, CreateAccountRequest, UpdateAccountRequest } from '../api/generated';
import { handleRejected, handlePending, StatusSliceBase, genericApiErrorMessage, initialStateBase, handleSuccess } from './sliceHelper';
import { RootState } from './store';
import { useAppSelector, useAppDispatch } from "../hooks/hooks";
import { useEffect } from 'react';
import { DeleteGroupAsync } from './groupsSlice';

export interface AccountsState {
  Accounts: AccountResponse[];

  GetAccountsRequest: StatusSliceBase;
  DeleteAccountRequest: StatusSliceBase;
  UpdateAccountRequest: StatusSliceBase;
  CreateAccountRequest: StatusSliceBase;
  CreateBulkAccountsRequest: StatusSliceBase;
  UploadAccountFileRequest: StatusSliceBase;
}
const initialState: AccountsState = {
  Accounts: [],

  GetAccountsRequest: { ...initialStateBase },
  UpdateAccountRequest: { ...initialStateBase },
  DeleteAccountRequest: { ...initialStateBase },
  UploadAccountFileRequest: { ...initialStateBase },
  CreateAccountRequest: { ...initialStateBase },
  CreateBulkAccountsRequest: { ...initialStateBase },
};

export const GetAccountsAsync = createAsyncThunk(
  'accounts/getAccounts',
  async (_, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().accounts.getAccounts()
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  },
  { condition: (_, { getState }) => (getState() as RootState).accounts.GetAccountsRequest.status !== "loading" }
);

export const CreateAccountAsync = createAsyncThunk(
  'accounts/createAccount',
  async (obj: CreateAccountRequest, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().accounts.createAccount(obj)
    } catch (err) {
      var error = (err as ApiError).body as ApiErrorResponse
      var errorTranslations: any = {
        10301: "Email-adresses findes allerede i systemet"
      }

      return rejectWithValue(errorTranslations[error.errorCode] || genericApiErrorMessage);
    }
  }
);

export const CreateBulkAccountsAsync = createAsyncThunk(
  'accounts/createBulkAccounts',
  async (obj: { groupIds: number[], emails: string[] }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().accounts.bulkCreateAccount({ groupIds: obj.groupIds, emails: obj.emails })
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const UpdateAccountAsync = createAsyncThunk(
  'accounts/updateAccount',
  async (obj: { id: number, body: UpdateAccountRequest }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().accounts.updateAccount(obj.id, obj.body)
    } catch (err) {
      var error = (err as ApiError).body as ApiErrorResponse
      var errorTranslations: any = {
        10301: "Email-adresses findes allerede i systemet"
      }

      return rejectWithValue(errorTranslations[error.errorCode] || genericApiErrorMessage);
    }
  }
);

export const DeleteAccountAsync = createAsyncThunk(
  'accounts/deleteAccount',
  async (id: number, { rejectWithValue }) => {
    try {
      await createCCMindApiClient().accounts.deleteAccount(id)
      return id
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const AccountsSlice = createSlice({
  name: 'Accounts',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(GetAccountsAsync.pending, (state) => handlePending(state.GetAccountsRequest));
    builder.addCase(GetAccountsAsync.rejected, (state, { payload }) => handleRejected(state.GetAccountsRequest, payload as string));
    builder.addCase(GetAccountsAsync.fulfilled, (state, action) => {
      handleSuccess(state.GetAccountsRequest);
      state.Accounts = action.payload;
    });

    builder.addCase(CreateAccountAsync.pending, (state) => handlePending(state.CreateAccountRequest));
    builder.addCase(CreateAccountAsync.rejected, (state, { payload }) => handleRejected(state.CreateAccountRequest, payload as string));
    builder.addCase(CreateAccountAsync.fulfilled, (state, action) => {
      handleSuccess(state.CreateAccountRequest);
      state.Accounts.push(action.payload);
    });

    builder.addCase(UpdateAccountAsync.pending, (state) => handlePending(state.UpdateAccountRequest));
    builder.addCase(UpdateAccountAsync.rejected, (state, { payload }) => handleRejected(state.UpdateAccountRequest, payload as string));
    builder.addCase(UpdateAccountAsync.fulfilled, (state, action) => {
      handleSuccess(state.UpdateAccountRequest);
      state.Accounts = state.Accounts.map(a => a.id === action.payload.id ? action.payload : a);
    });

    builder.addCase(CreateBulkAccountsAsync.pending, (state) => handlePending(state.CreateBulkAccountsRequest));
    builder.addCase(CreateBulkAccountsAsync.rejected, (state, { payload }) => handleRejected(state.CreateBulkAccountsRequest, payload as string));
    builder.addCase(CreateBulkAccountsAsync.fulfilled, (state, action) => {
      handleSuccess(state.CreateBulkAccountsRequest);
      // TODO: maybe load new accounts?
    });

    builder.addCase(DeleteAccountAsync.pending, (state) => handlePending(state.DeleteAccountRequest));
    builder.addCase(DeleteAccountAsync.rejected, (state, { payload }) => handleRejected(state.DeleteAccountRequest, payload as string));
    builder.addCase(DeleteAccountAsync.fulfilled, (state, action) => {
      handleSuccess(state.DeleteAccountRequest);
      state.Accounts = state.Accounts.filter(a => a.id !== action.payload);
    });

    builder.addCase(DeleteGroupAsync.fulfilled, (state, action) => {
      state.Accounts = state.Accounts.map(a => {
        a.groupIds = a.groupIds.filter(g => g !== action.payload);
        return a;
      });
    })
  },
});

export const selectAccountById = (id: number | null) => (state: RootState) => {
  if (id === null)
    return null;
  return state.accounts.Accounts.find(a => a.id === id) || null;
};

export const selectAccountsState = (state: RootState) => {
  return {
    accounts: state.accounts.Accounts,
    ...state.accounts.GetAccountsRequest
  }
};

export const selectCreateBulkAccountsState = (state: RootState) => state.accounts.CreateBulkAccountsRequest;
export const selectUpdateAccountState = (state: RootState) => state.accounts.UpdateAccountRequest;
export const selectCreateAccountState = (state: RootState) => state.accounts.CreateAccountRequest;
export const selectDeleteAccountState = (state: RootState) => state.accounts.DeleteAccountRequest;

export const useAccounts = () => {
  var state = useAppSelector(selectAccountsState);
  var dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetAccountsAsync());
  }, [dispatch])

  return state
}


export default AccountsSlice.reducer;