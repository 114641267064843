import { Alert, AlertIcon, Box, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  selectCreateBulkAccountsState,
  CreateBulkAccountsAsync,
} from '../../redux/accountsSlice'
import CustomModal from '../CustomModal'
import GroupSelector from '../form/inputs/GroupSelector'
import InputField, { InputFieldType } from '../form/inputs/InputField'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
}
const CreateBulkAccountsModal = (props: Props) => {
  const [groupIds, setGroupIds] = React.useState<number[]>([])
  const [input, setInput] = React.useState('')
  const [inputValidated, setInputValidated] = React.useState(false)
  const [errorMode, setErrorMode] = useState(false)

  const state = useAppSelector(selectCreateBulkAccountsState)
  const dispatch = useAppDispatch()
  const isValid = inputValidated

  const handleFormSubmit = () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }
    setErrorMode(false)

    var emails = input.split('\n').map((x) => x.trim().toLowerCase())
    emails = [...new Set(emails)]

    dispatch(CreateBulkAccountsAsync({ groupIds, emails })).then((value) => {
      if (value.meta.requestStatus === 'fulfilled') {
        setGroupIds([])
        setInput('')
        setInputValidated(false)
        props.onClose()
      }
    })
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={state.status === 'loading'}
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <InputField
              label="Email-adresser (én på hver linje)"
              name="emailAddresses"
              type={InputFieldType.textarea}
              onChangeHandler={setInput}
              onIsValidChangeHandler={setInputValidated}
              value={input || ''}
              placeholder="donald@trump.com
joe@biden.com"
              displayErrorIfInvalid={errorMode}
            />

            <GroupSelector
              isRequired={true}
              label="Gruppe"
              name="groupId"
              onChangeHandler={setGroupIds}
              value={groupIds}
              tooltip="Brugerne vil få adgang til kurser og materialer der tilhører gruppen."
              displayErrorIfInvalid={errorMode}
            />

            {state.status === 'failed' && (
              <Alert status="error">
                <AlertIcon />
                {state.errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </CustomModal>
  )
}

export default CreateBulkAccountsModal
