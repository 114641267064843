import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { createCCMindApiClient } from '../api/ApiClientFactory';
import { ApiError, ApiErrorResponse, MaterialResponse, UpdateMaterialRequest } from '../api/generated';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { DeleteEventAsync } from './eventsSlice';
import { handleRejected, handlePending, StatusSliceBase, genericApiErrorMessage, initialStateBase, handleSuccess } from './sliceHelper';
import { RootState } from './store';

export interface MaterialsState {
  Materials: MaterialResponse[];
  GetMaterialsRequest: StatusSliceBase;
  DeleteMaterialRequest: StatusSliceBase;
  UpdateMaterialRequest: StatusSliceBase;
  CreateMaterialRequest: StatusSliceBase;
  UploadMaterialFileRequest: StatusSliceBase;
  UploadMaterialPictureRequest: StatusSliceBase;
}
const initialState: MaterialsState = {
  Materials: [],
  GetMaterialsRequest: { ...initialStateBase },
  UpdateMaterialRequest: { ...initialStateBase },
  DeleteMaterialRequest: { ...initialStateBase },
  CreateMaterialRequest: { ...initialStateBase },
  UploadMaterialFileRequest: { ...initialStateBase },
  UploadMaterialPictureRequest: { ...initialStateBase },
};

export const GetMaterialsAsync = createAsyncThunk(
  'materials/getMaterials',
  async (_, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().materials.getMaterials()
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  },
  { condition: (_, { getState }) => (getState() as RootState).materials.GetMaterialsRequest.status !== "loading" });

export const CreateMaterialAsync = createAsyncThunk(
  'materials/createMaterial',
  async (obj: UpdateMaterialRequest, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().materials.createMaterial(obj)
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const UpdateMaterialAsync = createAsyncThunk(
  'materials/updateMaterial',
  async (obj: { id: number, body: UpdateMaterialRequest }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().materials.updateMaterial(obj.id, obj.body)
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const UploadMaterialFileAsync = createAsyncThunk(
  'materials/uploadMaterialFile',
  async (obj: { id: number, file: Blob, en: boolean }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().materials.uploadMaterialFile(obj.id, obj.en, { file: obj.file })
    } catch (err) {
      var error = (err as ApiError).body as ApiErrorResponse
      var translations: any = {
        50151: "Materialet mangler en titel til den uploadede fil",
        50100: "Filtypen blev ikke identificeret som værende en lyd eller video-fil"
      }
      return rejectWithValue(translations[error.errorCode] || genericApiErrorMessage);
    }
  });

export const UploadMaterialPictureAsync = createAsyncThunk(
  'materials/uploadMaterialPicture',
  async (obj: { id: number, file: Blob }, { rejectWithValue }) => {
    try {
      var response = await createCCMindApiClient().materials.uploadMaterialPic(obj.id, { file: obj.file })
      return response
    } catch (err) {
      var error = (err as ApiError).body as ApiErrorResponse
      var translations: any = {
        50101: "Følgende filtyper er tilladt: jpg, jpeg, png, tif"
      }
      return rejectWithValue(translations[error.errorCode] || genericApiErrorMessage);
    }
  });

export const DeleteMaterialAsync = createAsyncThunk(
  'materials/deleteMaterial',
  async (id: number, { rejectWithValue }) => {
    try {
      await createCCMindApiClient().materials.deleteMaterial(id)
      return id;
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const MaterialsSlice = createSlice({
  name: 'Materials',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetMaterialsAsync.pending, (state) => handlePending(state.GetMaterialsRequest));
    builder.addCase(GetMaterialsAsync.rejected, (state, { payload }) => handleRejected(state.GetMaterialsRequest, payload as string));
    builder.addCase(GetMaterialsAsync.fulfilled, (state, action) => {
      handleSuccess(state.GetMaterialsRequest);
      state.Materials = action.payload;
    });

    builder.addCase(CreateMaterialAsync.pending, (state) => handlePending(state.CreateMaterialRequest));
    builder.addCase(CreateMaterialAsync.rejected, (state, { payload }) => handleRejected(state.CreateMaterialRequest, payload as string));
    builder.addCase(CreateMaterialAsync.fulfilled, (state, action) => {
      handleSuccess(state.CreateMaterialRequest);
      state.Materials.push(action.payload);
    });

    builder.addCase(UpdateMaterialAsync.pending, (state) => handlePending(state.UpdateMaterialRequest));
    builder.addCase(UpdateMaterialAsync.rejected, (state, { payload }) => handleRejected(state.UpdateMaterialRequest, payload as string));
    builder.addCase(UpdateMaterialAsync.fulfilled, (state, action) => {
      handleSuccess(state.UpdateMaterialRequest);
      state.Materials = state.Materials.map(m => m.id === action.payload.id ? action.payload : m);
    });

    builder.addCase(UploadMaterialFileAsync.pending, (state) => handlePending(state.UploadMaterialFileRequest));
    builder.addCase(UploadMaterialFileAsync.rejected, (state, { payload }) => handleRejected(state.UploadMaterialFileRequest, payload as string));
    builder.addCase(UploadMaterialFileAsync.fulfilled, (state, action) => {
      handleSuccess(state.UploadMaterialFileRequest);
      state.Materials = state.Materials.map(m => m.id === action.payload.id ? action.payload : m);
    });

    builder.addCase(UploadMaterialPictureAsync.pending, (state) => handlePending(state.UploadMaterialPictureRequest));
    builder.addCase(UploadMaterialPictureAsync.rejected, (state, { payload }) => handleRejected(state.UploadMaterialPictureRequest, payload as string));
    builder.addCase(UploadMaterialPictureAsync.fulfilled, (state, action) => {
      handleSuccess(state.UploadMaterialPictureRequest);
      state.Materials = state.Materials.map(m => m.id === action.payload.id ? action.payload : m);
    });

    builder.addCase(DeleteMaterialAsync.pending, (state) => handlePending(state.DeleteMaterialRequest));
    builder.addCase(DeleteMaterialAsync.rejected, (state, { payload }) => handleRejected(state.DeleteMaterialRequest, payload as string));
    builder.addCase(DeleteMaterialAsync.fulfilled, (state, action) => {
      handleSuccess(state.DeleteMaterialRequest);
      state.Materials = state.Materials.filter(m => m.id !== action.payload);
    });

    builder.addCase(DeleteEventAsync.fulfilled, (state, action) => {
      state.Materials = state.Materials.filter(m => m.id !== action.payload);
    })
  },
});

export const useMaterials = () => {
  var state = useAppSelector(selectMaterialsState);
  var dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetMaterialsAsync());
  }, [dispatch])

  return state
}

export const selectMaterialsState = (state: RootState) => {
  return {
    materials: state.materials.Materials,
    ...state.materials.GetMaterialsRequest
  }
};
export const selectUpdateMaterialState = (state: RootState) => state.materials.UpdateMaterialRequest;
export const selectUploadMaterialState = (state: RootState) => state.materials.UploadMaterialFileRequest;
export const selectUploadMaterialPictureState = (state: RootState) => state.materials.UploadMaterialPictureRequest;
export const selectCreateMaterialState = (state: RootState) => state.materials.CreateMaterialRequest;
export const selectDeleteMaterialState = (state: RootState) => state.materials.DeleteMaterialRequest;
export const selectMaterialById = (id: number | null) => (state: RootState) => state.materials.Materials.find(m => m.id === id) || null;

export default MaterialsSlice.reducer;