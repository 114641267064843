import { QuestionIcon } from '@chakra-ui/icons'
import {
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'

interface Props {
  label: string
  name: string
  value: string | null
  placeholder?: string
  loading?: boolean
  options: {
    value: string
    label: string
  }[]
  tooltip?: string
  displayErrorIfInvalid?: boolean
  isRequired?: boolean
  onChangeHandler: (value: string | null) => void
}

const InputSelect = (props: Props) => {
  const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChangeHandler(
      event.target.value === '-1' ? null : event.target.value
    )
  }

  return (
    <>
      <FormControl
        isRequired={props.isRequired}
        isInvalid={
          props.displayErrorIfInvalid && props.isRequired && !props.value
        }
      >
        <FormLabel htmlFor={props.name}>
          {props.label}{' '}
          {props.tooltip && (
            <Tooltip label={props.tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </FormLabel>
        {props.loading ? (
          <Spinner size="sm" />
        ) : (
          <Select
            size="md"
            onChange={changeHandler}
            value={props.value ?? undefined}
          >
            {props.placeholder && (
              <option value="-1">{props.placeholder}</option>
            )}
            {props.options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        )}
      </FormControl>
    </>
  )
}

export default InputSelect
