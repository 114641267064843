import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { createCCMindApiClient } from '../api/ApiClientFactory';
import { GroupResponse } from '../api/generated';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { handleRejected, handlePending, StatusSliceBase, genericApiErrorMessage, initialStateBase, handleSuccess } from './sliceHelper';
import { RootState } from './store';

export interface GroupsState {
  GetGroupsRequest: StatusSliceBase;
  Groups: GroupResponse[];
  CreateGroupRequest: StatusSliceBase;
  UpdateGroupRequest: StatusSliceBase;
  DeleteGroupRequest: StatusSliceBase;
  AssignGroupEventsRequest: StatusSliceBase;
}
const initialState: GroupsState = {
  Groups: [],
  GetGroupsRequest: { ...initialStateBase },
  CreateGroupRequest: { ...initialStateBase },
  UpdateGroupRequest: { ...initialStateBase },
  DeleteGroupRequest: { ...initialStateBase },
  AssignGroupEventsRequest: { ...initialStateBase },
};

export const GetGroupsAsync = createAsyncThunk(
  'groups/getGroups',
  async (_, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().groups.getGroups()
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  },
  { condition: (_, { getState }) => (getState() as RootState).groups.GetGroupsRequest.status !== "loading" });

export const CreateGroupAsync = createAsyncThunk(
  'groups/createGroup',
  async (obj: { name: string }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().groups.createGroup({ name: obj.name })
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const UpdateGroupAsync = createAsyncThunk(
  'groups/updateGroup',
  async (obj: { id: number, name: string }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().groups.updateGroup(obj.id, { name: obj.name })
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const AssignGroupEventsAsync = createAsyncThunk(
  'groups/assignGroupEvents',
  async (obj: { id: number, eventIds: number[] }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().groups.assignGroupEvents(obj.id, { eventIds: obj.eventIds })
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const DeleteGroupAsync = createAsyncThunk(
  'groups/deleteGroup',
  async (id: number, { rejectWithValue }) => {
    try {
      await createCCMindApiClient().groups.deleteGroup(id)
      return id
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const GroupsSlice = createSlice({
  name: 'Groups',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(GetGroupsAsync.pending, (state) => handlePending(state.GetGroupsRequest));
    builder.addCase(GetGroupsAsync.rejected, (state, { payload }) => handleRejected(state.GetGroupsRequest, payload as string));
    builder.addCase(GetGroupsAsync.fulfilled, (state, action) => {
      handleSuccess(state.GetGroupsRequest);
      state.Groups = action.payload;
    });

    builder.addCase(CreateGroupAsync.pending, (state) => handlePending(state.CreateGroupRequest));
    builder.addCase(CreateGroupAsync.rejected, (state, { payload }) => handleRejected(state.CreateGroupRequest, payload as string));
    builder.addCase(CreateGroupAsync.fulfilled, (state, action) => {
      handleSuccess(state.CreateGroupRequest);
      state.Groups.push(action.payload);
    });

    builder.addCase(UpdateGroupAsync.pending, (state) => handlePending(state.UpdateGroupRequest));
    builder.addCase(UpdateGroupAsync.rejected, (state, { payload }) => handleRejected(state.UpdateGroupRequest, payload as string));
    builder.addCase(UpdateGroupAsync.fulfilled, (state, action) => {
      handleSuccess(state.UpdateGroupRequest);
      state.Groups = state.Groups.map(g => g.id === action.payload.id ? action.payload : g);
    });

    builder.addCase(AssignGroupEventsAsync.pending, (state) => handlePending(state.AssignGroupEventsRequest));
    builder.addCase(AssignGroupEventsAsync.rejected, (state, { payload }) => handleRejected(state.AssignGroupEventsRequest, payload as string));
    builder.addCase(AssignGroupEventsAsync.fulfilled, (state, action) => {
      handleSuccess(state.AssignGroupEventsRequest);
      state.Groups = state.Groups.map(g => g.id === action.payload.id ? action.payload : g);
    });

    builder.addCase(DeleteGroupAsync.pending, (state) => handlePending(state.DeleteGroupRequest));
    builder.addCase(DeleteGroupAsync.rejected, (state, { payload }) => handleRejected(state.DeleteGroupRequest, payload as string));
    builder.addCase(DeleteGroupAsync.fulfilled, (state, action) => {
      handleSuccess(state.DeleteGroupRequest);
      state.Groups = state.Groups.filter(g => g.id !== action.payload);
    });
  },
});


export const selectGroupById = (id: number | null) => (state: RootState) => {
  if (id === null)
    return null;
  return state.groups.Groups.find(g => g.id === id) || null;
};

export const selectGroupsState = (state: RootState) => {
  return {
    groups: state.groups.Groups,
    ...state.groups.GetGroupsRequest
  }
};

export const selectCreateGroupState = (state: RootState) => state.groups.CreateGroupRequest;
export const selectUpdateGroupState = (state: RootState) => state.groups.UpdateGroupRequest;
export const selectAssignGroupEventsState = (state: RootState) => state.groups.AssignGroupEventsRequest;


export const useGroups = () => {
  var state = useAppSelector(selectGroupsState);
  var dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetGroupsAsync());
  }, [dispatch])

  return state
}

export default GroupsSlice.reducer;