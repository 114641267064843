import {
  Box,
  Container,
  Heading,
  Stack,
  Spinner,
  HStack,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import React from 'react'
import { Column } from 'react-table'
import { GroupResponse } from '../api/generated'
import CustomAlertDialog from '../components/CustomAlertDialog'
import CustomButton from '../components/CustomButton'
import AssignGroupEventsModal from '../components/modal/AssignGroupEventsModal'
import CreateUpdateGroupModal from '../components/modal/CreateUpdateGroupModal'
import CustomTable, { createDefaultColumn } from '../components/table/CustomTable'
import { useAppDispatch, useRequireAuth } from '../hooks/hooks'
import { DeleteGroupAsync, useGroups } from '../redux/groupsSlice'

const Groups = () => {
  useRequireAuth()
  const dispatch = useAppDispatch()
  const { groups, status, lastUpdated, errorMessage } = useGroups()

  const [deleteIsOpen, setDeleteIsOpen] = React.useState(false)
  const [createIsOpen, setCreateIsOpen] = React.useState(false)
  const [updateIsOpen, setUpdateIsOpen] = React.useState(false)
  const [assignMatsIsOpen, setAssignMatsIsOpen] = React.useState(false)
  const [groupToEdit, setGroupToEdit] = React.useState<GroupResponse | null>(null)

  const deleteClickHandler = (obj: GroupResponse) => {
    setDeleteIsOpen(true)
    setGroupToEdit(obj)
  }

  const updateClickHandler = (obj: GroupResponse) => {
    setUpdateIsOpen(true)
    setGroupToEdit(obj)
  }

  const assignMatsClickHandler = (obj: GroupResponse) => {
    setAssignMatsIsOpen(true)
    setGroupToEdit(obj)
  }

  const deleteSubmitHandler = () => {
    dispatch(DeleteGroupAsync(groupToEdit!.id))
    setDeleteIsOpen(false)
    setGroupToEdit(null)
  }

  const columns: Column<GroupResponse>[] = [
    createDefaultColumn({ header: 'Id', accessor: (x) => x.id }),
    createDefaultColumn({ header: 'Fulde navn', accessor: (x) => x.name }),
    createDefaultColumn({
      header: 'Oprettet',
      accessor: (x) => new Date(x.createdDate),
      sortType: "datetime",
      Cell: ({ value }: { value: Date }) => value.toLocaleString(),
    }),
    createDefaultColumn({
      header: 'Handlinger',
      accessor: (x) => x,
      disableSortBy: true,
      Cell: (cellProps) => (
        <>
          <CustomButton
            title="Ændr"
            color="green"
            onClickHandler={() => updateClickHandler(cellProps.value)}
          />
          <CustomButton
            title="Tildel forløb"
            color="green"
            onClickHandler={() => assignMatsClickHandler(cellProps.value)}
          />
          <CustomButton
            title="Slet"
            color="red"
            onClickHandler={() => deleteClickHandler(cellProps.value)}
          />
        </>
      ),
    }),
  ]

  return (
    <Container paddingTop="50px" maxW={'150ch'}>
      <Stack spacing={10}>
        <Heading as="h1" size="xl">
          Grupper
        </Heading>
        <Box>
          <HStack justifyContent={'space-between'}>
            <CustomButton
              title="Opret gruppe"
              onClickHandler={() => setCreateIsOpen(true)}
              color="green"
            />
            {!!lastUpdated && (
              <Text color="gray.500">
                Sidst opdateret: {new Date(lastUpdated).toLocaleString()}
              </Text>
            )}
          </HStack>
        </Box>
        {!!errorMessage ? (
          <Alert status="error">
            <AlertIcon />
            {errorMessage}
          </Alert>
        ) : status === 'loading' && !lastUpdated ? (
          <Spinner size="lg" />
        ) : (
          <CustomTable columns={columns} data={groups} />
        )}
      </Stack>
      <CreateUpdateGroupModal
        title="Opret gruppe/virksomhed"
        content={`Opret en ny gruppe. Grupper kan tildeles adgang til forløb og tilhørende materialer.
        Brugere der er medlem af gruppen får adgang til disse materialer.`}
        isOpen={createIsOpen}
        groupId={null}
        onClose={() => setCreateIsOpen(false)}
      />
      <CreateUpdateGroupModal
        title="Opdater gruppe/virksomhed"
        content={`Opdater en eksisterende gruppe. Dette har ingen effekt på brugere, der er medlem af gruppen.`}
        isOpen={updateIsOpen}
        groupId={groupToEdit?.id ?? null}
        onClose={() => setUpdateIsOpen(false)}
      />
      <AssignGroupEventsModal
        title="Tildel forløb til gruppe"
        content={`Forløb kan bruges af flere forskellige grupper.`}
        isOpen={assignMatsIsOpen}
        groupId={groupToEdit?.id ?? null}
        onClose={() => setAssignMatsIsOpen(false)}
      />
      <CustomAlertDialog
        title="Er du sikker på at du vil slette?"
        content={`Gruppen ${groupToEdit?.name} vil slettes permanent og kan ikke gendannes. Alle brugere i gruppen vil blive fjernet.`}
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onSubmit={deleteSubmitHandler}
      />
    </Container>
  )
}

export default Groups
