import {
  Box,
  Container,
  Heading,
  Stack,
  Spinner,
  HStack,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import React from 'react'
import { Column } from 'react-table'
import { ContentResponse } from '../api/generated'
import CustomButton from '../components/CustomButton'
import UpdateContentModal from '../components/modal/UpdateContentModal'
import CustomTable, {
  createDefaultColumn,
} from '../components/table/CustomTable'
import { useRequireAuth } from '../hooks/hooks'
import { useContents } from '../redux/contentsSlice'

const Contents = () => {
  useRequireAuth()
  const { contents, status, lastUpdated, errorMessage } = useContents()

  const [updateIsOpen, setUpdateIsOpen] = React.useState(false)
  const [contentToEdit, setContentToEdit] =
    React.useState<ContentResponse | null>(null)

  const updateClickHandler = (obj: ContentResponse) => {
    setUpdateIsOpen(true)
    setContentToEdit(obj)
  }

  const columns: Column<ContentResponse>[] = [
    createDefaultColumn({ header: 'Id', accessor: (x) => `${x.id} - ${x.friendlyName}`}),
    createDefaultColumn({
      header: 'Tekst (dansk)',
      accessor: (x) => x.content,
    }),
    createDefaultColumn({
      header: 'Tekst (engelsk)',
      accessor: (x) => x.contentEn,
    }),
    createDefaultColumn({
      header: 'Oprettet',
      accessor: (x) => new Date(x.createdDate),
      sortType: 'datetime',
      Cell: ({ value }: { value: Date }) => value.toLocaleString(),
    }),
    createDefaultColumn({
      header: 'Handlinger',
      accessor: (x) => x,
      disableSortBy: true,
      Cell: (cellProps) => (
        <>
          <CustomButton
            title="Ændr"
            color="green"
            onClickHandler={() => updateClickHandler(cellProps.value)}
          />
        </>
      ),
    }),
  ]

  return (
    <Container paddingTop="50px" maxW={'150ch'}>
      <Stack spacing={10}>
        <Heading as="h1" size="xl">
          Tekster
        </Heading>
        <Box>
          <HStack justifyContent={'right'}>
            {!!lastUpdated && (
              <Text color="gray.500">
                Sidst opdateret: {new Date(lastUpdated).toLocaleString()}
              </Text>
            )}
          </HStack>
        </Box>
        {!!errorMessage ? (
          <Alert status="error">
            <AlertIcon />
            {errorMessage}
          </Alert>
        ) : status === 'loading' && !lastUpdated ? (
          <Spinner size="lg" />
        ) : (
          <CustomTable columns={columns} data={contents} />
        )}
      </Stack>
      <UpdateContentModal
        title="Opdater tekst-indhold"
        content={`Opdater eksisterende tekst-inhold.`}
        isOpen={updateIsOpen}
        contentId={contentToEdit?.id ?? null}
        onClose={() => setUpdateIsOpen(false)}
      />
    </Container>
  )
}

export default Contents
