import { localStorageAuthUserKey } from "../redux/loginSlice";
import { CCMindApiClient } from "./generated";

export function createCCMindApiClient() {
    var token = localStorage.getItem(localStorageAuthUserKey);
    var apiClient = new CCMindApiClient({
        BASE: process.env.REACT_APP_API_BASEURL,
        TOKEN: token ? token : undefined
    })
    return apiClient;
}