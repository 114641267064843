/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountsService } from './services/AccountsService';
import { ContentsService } from './services/ContentsService';
import { EventsService } from './services/EventsService';
import { GroupsService } from './services/GroupsService';
import { MaterialsService } from './services/MaterialsService';
import { NewsItemsService } from './services/NewsItemsService';
import { SendGridService } from './services/SendGridService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class CCMindApiClient {

    public readonly accounts: AccountsService;
    public readonly contents: ContentsService;
    public readonly events: EventsService;
    public readonly groups: GroupsService;
    public readonly materials: MaterialsService;
    public readonly newsItems: NewsItemsService;
    public readonly sendGrid: SendGridService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accounts = new AccountsService(this.request);
        this.contents = new ContentsService(this.request);
        this.events = new EventsService(this.request);
        this.groups = new GroupsService(this.request);
        this.materials = new MaterialsService(this.request);
        this.newsItems = new NewsItemsService(this.request);
        this.sendGrid = new SendGridService(this.request);
    }
}