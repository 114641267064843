import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { EventResponse } from '../../api/generated'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  AssignGroupEventsAsync,
  selectAssignGroupEventsState,
  selectGroupById,
} from '../../redux/groupsSlice'
import { useEvents } from '../../redux/eventsSlice'
import CustomModal from '../CustomModal'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  groupId: number | null
}
const AssignGroupEventsModal = ({ isOpen, ...props }: Props) => {
  const [chosenEvents, setChosenEvents] = useState<EventResponse[]>([])
  const [availableEvents, setAvailableEvents] = useState<EventResponse[]>([])
  const [errorMode, setErrorMode] = useState(false)

  const { events: allEvents, ...eventsState } = useEvents()
  const assignGroupEventsState = useAppSelector(selectAssignGroupEventsState)
  const thisGroup = useAppSelector(selectGroupById(props.groupId))
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isOpen) return
    setChosenEvents([])
    setAvailableEvents([])
    setErrorMode(false)
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) return

    var alreadyAssignedEvents = thisGroup?.events.map((ev) => ev) ?? []

    setChosenEvents(
      alreadyAssignedEvents.sort((a, b) =>
        (a.title ?? '') < (b.title ?? '') ? -1 : 1
      )
    )
  }, [thisGroup, isOpen])

  useEffect(() => {
    setAvailableEvents(
      allEvents.filter((x) => !chosenEvents.map((x) => x.id).includes(x.id))
    )
  }, [allEvents, chosenEvents])

  const isValid =
    eventsState.status !== 'loading' &&
    thisGroup != null &&
    assignGroupEventsState.status !== 'loading'

  const handleFormSubmit = () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)
    dispatch(
      AssignGroupEventsAsync({
        id: props.groupId!,
        eventIds: chosenEvents.map((x) => x.id),
      })
    ).then(
      (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
    )
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={assignGroupEventsState.status === 'loading'}
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <VStack spacing={3}>
            <Flex width={'100%'} gap={4}>
              <Box flexBasis={'100%'}>
                <Heading as="h2" size="sm" fontWeight={500}>
                  Tilgængelige forløb
                </Heading>
                <Text color="gray.500">
                  Du kan trække disse over til tabellen til højre
                </Text>
                <ReactSortable
                  style={{ minHeight: '250px', paddingTop: '10px' }}
                  group={'shared-group-1'}
                  tag="div"
                  list={availableEvents.map((x) => ({ ...x }))}
                  setList={(list) => setAvailableEvents(list)}
                  sort={false}
                >
                  {availableEvents.map((event) => (
                    <div key={event.id}>
                      <Box
                        bg="gray.400"
                        w="100%"
                        p={2}
                        color="white"
                        marginBottom={1}
                        cursor="move"
                      >
                        <Text>{event.title}</Text>
                      </Box>
                    </div>
                  ))}
                </ReactSortable>
              </Box>
              <Box flexBasis="100%">
                <Heading as="h2" size="sm" fontWeight={500}>
                  Valgte forløb for denne gruppe
                </Heading>
                <Text color="gray.500">
                  Rækken kan ikke sorteres. De er sorteret efter start dato
                </Text>
                <ReactSortable
                  style={{ minHeight: '250px', paddingTop: '10px' }}
                  group={'shared-group-1'}
                  tag="div"
                  list={chosenEvents.map((x) => ({ ...x }))}
                  sort={false}
                  setList={(list) => setChosenEvents(list)}
                >
                  {chosenEvents.map((event, i) => (
                    <div key={event.id}>
                      <Box
                        bg="blue.500"
                        w="100%"
                        p={2}
                        color="white"
                        marginBottom={1}
                        cursor="move"
                      >
                        <Text>{event.title}</Text>
                      </Box>
                    </div>
                  ))}
                </ReactSortable>
              </Box>
            </Flex>
          </VStack>

          {assignGroupEventsState.status === 'failed' && (
            <Alert status="error">
              <AlertIcon />
              {assignGroupEventsState.errorMessage ||
                assignGroupEventsState.errorMessage}
            </Alert>
          )}
        </form>
      </Box>
    </CustomModal>
  )
}

export default AssignGroupEventsModal
