import { ReactNode } from 'react'
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import { appRoutes } from '../../appRoutes'
import { NavLink } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { logout, selectLoginFullname } from '../../redux/loginSlice'

const Links = [
  { title: 'Forside', route: appRoutes.home },
  { title: 'Tekster', route: appRoutes.contents },
  // { title: 'Brugere', route: appRoutes.accounts },
  // { title: 'Grupper', route: appRoutes.groups },
  { title: 'Forløb', route: appRoutes.events },
  { title: 'Materialer', route: appRoutes.materials },
  { title: 'Nyheder', route: appRoutes.newsItems },
]

const MenuLink = ({ children, to }: { children: ReactNode; to: string }) => (
  <Link
    as={NavLink}
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    _activeLink={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    outline={undefined}
    to={to}
  >
    {children}
  </Link>
)

export default function Header() {
  const dispatch = useAppDispatch()
  const loginFullname = useAppSelector(selectLoginFullname);
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <HStack
              as={'nav'}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}
            >
              {Links.map((link) => (
                <MenuLink to={link.route} key={link.title}>
                  {link.title}
                </MenuLink>
              ))}
            </HStack>
          </HStack>
          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}
              >
                {loginFullname}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => dispatch(logout())}>Log ud</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <MenuLink to={link.route} key={link.title}>
                  {link.title}
                </MenuLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  )
}
