/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMaterialRequest } from '../models/CreateMaterialRequest';
import type { MaterialResponse } from '../models/MaterialResponse';
import type { UpdateMaterialRequest } from '../models/UpdateMaterialRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class MaterialsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new material
     * @param requestBody 
     * @returns MaterialResponse Created
     * @throws ApiError
     */
    public createMaterial(
requestBody?: CreateMaterialRequest,
): CancelablePromise<MaterialResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Materials',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get materials
     * @param freeOfCharge Filter by free of charge
     * @returns MaterialResponse Success
     * @throws ApiError
     */
    public getMaterials(
freeOfCharge?: boolean,
): CancelablePromise<Array<MaterialResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Materials',
            query: {
                'freeOfCharge': freeOfCharge,
            },
        });
    }

    /**
     * Update an existing material
     * @param id 
     * @param requestBody 
     * @returns MaterialResponse Created
     * @throws ApiError
     */
    public updateMaterial(
id: number,
requestBody?: UpdateMaterialRequest,
): CancelablePromise<MaterialResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Materials/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>40101</i> - The material doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Get material
     * @param id 
     * @returns MaterialResponse Success
     * @throws ApiError
     */
    public getMaterial(
id: number,
): CancelablePromise<MaterialResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Materials/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>40101</i> - The material doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Delete material
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public deleteMaterial(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Materials/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>40101</i> - The material doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Update the material file
 * The previous file will be permanently deleted, if any existed.
     * @param id 
     * @param en 
     * @param formData 
     * @returns MaterialResponse Created
     * @throws ApiError
     */
    public uploadMaterialFile(
id: number,
en: boolean = false,
formData?: {
file?: Blob;
},
): CancelablePromise<MaterialResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Materials/{id}/uploadFile',
            path: {
                'id': id,
            },
            query: {
                'en': en,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>40101</i> - The material doesn't exist</li></ul>`,
                422: `<p>Error codes:</p><ul><li><i>50100</i> - The file must be a either video or audio file</li><li><i>50151</i> - The material does not have a title to be used for the uploaded file.</li></ul>`,
            },
        });
    }

    /**
     * Update the picture for a material.
 * The previous picture will be permanently deleted.
     * @param id 
     * @param formData 
     * @returns MaterialResponse Created
     * @throws ApiError
     */
    public uploadMaterialPic(
id: number,
formData?: {
file?: Blob;
},
): CancelablePromise<MaterialResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Materials/{id}/uploadPicture',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>40101</i> - The material doesn't exist</li></ul>`,
                422: `<p>Error codes:</p><ul><li><i>50101</i> - The file extension is not allowed for the operation.</li></ul>`,
            },
        });
    }

}