import { Alert, AlertIcon, Box, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  UpdateAccountAsync,
  selectUpdateAccountState,
  selectAccountById,
  CreateAccountAsync,
  selectCreateAccountState,
} from '../../redux/accountsSlice'
import CustomModal from '../CustomModal'
import CustomCheckbox from '../form/inputs/CustomCheckbox'
import GroupSelector from '../form/inputs/GroupSelector'
import InputField, { InputFieldType } from '../form/inputs/InputField'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  accountId: number | null // if null, create new account
}
const CreateUpdateAccountModal = (props: Props) => {
  const [email, setEmail] = React.useState('')
  const [fullName, setFullName] = React.useState<string | null>('')
  const [groupIds, setGroupIds] = React.useState<number[]>([])
  const [isAdmin, setIsAdmin] = React.useState(false)

  const [emailValidated, setEmailValidated] = React.useState(false)
  const [errorMode, setErrorMode] = useState(false)

  const updateAccState = useAppSelector(selectUpdateAccountState)
  const createAccState = useAppSelector(selectCreateAccountState)
  const accountToUpdate = useAppSelector(selectAccountById(props.accountId))
  const dispatch = useAppDispatch()
  const isValid = emailValidated && groupIds != null

  useEffect(() => {
    if(!props.isOpen || !accountToUpdate) return
      setEmail(accountToUpdate.email)
      setFullName(accountToUpdate.fullName)
      setGroupIds(accountToUpdate.groupIds)
      setIsAdmin(accountToUpdate.isAdmin)
    }, [props.isOpen, accountToUpdate])

  useEffect(() => {
    if(props.isOpen) return
    setEmail('')
    setFullName('')
    setGroupIds([])
    setIsAdmin(false)
    setErrorMode(false)
  }, [props.isOpen])

  const handleFormSubmit = () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)
    if (!!accountToUpdate)
      dispatch(
        UpdateAccountAsync({
          id: accountToUpdate!.id,
          body: { email, fullName, groupIds, isAdmin },
        })
      ).then(
        (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
      )
    else
      dispatch(
        CreateAccountAsync({ email, fullName, groupIds, isAdmin })
      ).then(
        (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
      )
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={
        updateAccState.status === 'loading' ||
        createAccState.status === 'loading'
      }
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <InputField
              label="Fulde navn"
              name="fullName"
              type={InputFieldType.text}
              onChangeHandler={setFullName}
              value={fullName || ''}
              placeholder="Donald Trump"
              displayErrorIfInvalid={errorMode}
            />

            <InputField
              isRequired={true}
              label="Email"
              name="email"
              type={InputFieldType.email}
              onChangeHandler={setEmail}
              onIsValidChangeHandler={setEmailValidated}
              value={email}
              placeholder="donald@trump.com"
              displayErrorIfInvalid={errorMode}
            />

            <CustomCheckbox
              label="Administrator"
              name="isAdmin"
              tooltip="Hvis du vælger denne, vil brugeren kunne logge ind i administrationspanelet."
              onChangeHandler={setIsAdmin}
              isChecked={isAdmin}
            />

            <GroupSelector
              isRequired={true}
              label="Gruppe"
              name="groupId"
              onChangeHandler={setGroupIds}
              value={groupIds}
              tooltip="Brugeren vil få adgang til kurser og materialer der tilhører gruppen."
              displayErrorIfInvalid={errorMode}
            />

            {(updateAccState.status === 'failed' ||
              createAccState.status === 'failed') && (
              <Alert status="error">
                <AlertIcon />
                {updateAccState.errorMessage ?? createAccState.errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </CustomModal>
  )
}

export default CreateUpdateAccountModal
