import { Alert, AlertIcon, Box, Stack } from "@chakra-ui/react"
import React, { FormEvent, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../hooks/hooks"
import {
  selectUpdateLoginPasswordState,
  updateLoginPasswordAsync,
} from "../../redux/loginSlice"
import InputField, { InputFieldType } from "./inputs/InputField"
import InputSubmitButton from "./inputs/InputSubmitButton"

interface Props {
  resetPasswordToken: string
  isEnLang: boolean
}

const UpdateLoginPasswordForm = (props: Props) => {
  const [password, setPassword] = React.useState("")
  const [passwordValidated, setPasswordValidated] = React.useState(false)
  const [errorMode, setErrorMode] = useState(false)
  const [tempLockout, setTempLockout] = useState(false)
  const [success, setSuccess] = useState(false)

  var dispatch = useAppDispatch()
  var updateLoginPasswordState = useAppSelector(selectUpdateLoginPasswordState)

  useEffect(() => {
    if (tempLockout === false) return
    let timeout = setTimeout(() => setTempLockout(false), 3000)
    return () => clearTimeout(timeout)
  }, [tempLockout])

  const isValid = passwordValidated
  const isEligible =
    !tempLockout && updateLoginPasswordState.status !== "loading"

  let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSuccess(false)
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)
    setTempLockout(true)
    dispatch(
      updateLoginPasswordAsync({
        password,
        resetPasswordToken: props.resetPasswordToken,
      })
    ).then((value) => {
      if (value.meta.requestStatus === "fulfilled") {
        setSuccess(true)
      }
    })
  }

  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={3}>
          <InputField
            label={props.isEnLang ? "New password" : "Ny adgangskode"}
            name="password"
            type={InputFieldType.password}
            onChangeHandler={setPassword}
            onIsValidChangeHandler={setPasswordValidated}
            value={password}
            placeholder={props.isEnLang ? "New password" : "Ny adgangskode"}
            displayErrorIfInvalid={errorMode}
            isRequired={true}
          />

          <InputSubmitButton
            disabled={!isEligible}
            submitting={updateLoginPasswordState.status === "loading"}
            title={props.isEnLang ? "Save password" : "Gem adgangskode"}
          />

          {updateLoginPasswordState.status === "failed" && (
            <Alert status="error">
              <AlertIcon />
              {updateLoginPasswordState.errorMessage}
            </Alert>
          )}

          {!!success && (
            <Alert status="success">
              <AlertIcon />
              {props.isEnLang
                ? "Your password was saved. You can now sign in to the C&C Mind app with your email and new password."
                : "Din adgangskode blev gemt. Du kan nu logge ind i C&C Mind app'en med din email og nye adganskode."}
            </Alert>
          )}
        </Stack>
      </form>
    </Box>
  )
}

export default UpdateLoginPasswordForm
