import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { createCCMindApiClient } from '../api/ApiClientFactory';
import { CreateEventRequest, EventDetailedResponse, UpdateEventRequest } from '../api/generated';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { handleRejected, handlePending, StatusSliceBase, genericApiErrorMessage, initialStateBase, handleSuccess } from './sliceHelper';
import { RootState } from './store';

export interface EventsState {
  Events: EventDetailedResponse[];
  GetDetailedEventRequest: StatusSliceBase;
  GetEventsRequest: StatusSliceBase;
  CreateEventRequest: StatusSliceBase;
  UpdateEventRequest: StatusSliceBase;
  DeleteEventRequest: StatusSliceBase;
  AssignEventMaterialsRequest: StatusSliceBase;
}
const initialState: EventsState = {
  Events: [],
  GetDetailedEventRequest: { ...initialStateBase },
  GetEventsRequest: { ...initialStateBase },
  CreateEventRequest: { ...initialStateBase },
  UpdateEventRequest: { ...initialStateBase },
  DeleteEventRequest: { ...initialStateBase },
  AssignEventMaterialsRequest: { ...initialStateBase },
};

export const GetEventsAsync = createAsyncThunk(
  'events/getEvents',
  async (_, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().events.getEvents()
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  },
  { condition: (_, { getState }) => (getState() as RootState).events.GetEventsRequest.status !== "loading" });

export const CreateEventAsync = createAsyncThunk(
  'events/createEvent',
  async (obj: CreateEventRequest, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().events.createEvent(obj)
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const UpdateEventAsync = createAsyncThunk(
  'events/updateEvent',
  async (obj: { id: number, body: UpdateEventRequest }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().events.updateEvent(obj.id, obj.body)
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const AssignEventMaterialsAsync = createAsyncThunk(
  'events/assignEventMaterials',
  async (obj: { id: number, materialIds: number[] }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().events.assignEventMaterials(obj.id, {
        items: obj.materialIds.map((x, i) => ({ materialId: x, sortOrder: i }))
      })
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const DeleteEventAsync = createAsyncThunk(
  'events/deleteEvent',
  async (id: number, { rejectWithValue }) => {
    try {
      await createCCMindApiClient().events.deleteEvent(id)
      return id
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  }
);

export const EventsSlice = createSlice({
  name: 'Events',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(GetEventsAsync.pending, (state) => handlePending(state.GetEventsRequest));
    builder.addCase(GetEventsAsync.rejected, (state, { payload }) => handleRejected(state.GetEventsRequest, payload as string));
    builder.addCase(GetEventsAsync.fulfilled, (state, action) => {
      handleSuccess(state.GetEventsRequest);
      state.Events = action.payload;
    });

    builder.addCase(CreateEventAsync.pending, (state) => handlePending(state.CreateEventRequest));
    builder.addCase(CreateEventAsync.rejected, (state, { payload }) => handleRejected(state.CreateEventRequest, payload as string));
    builder.addCase(CreateEventAsync.fulfilled, (state, action) => {
      handleSuccess(state.CreateEventRequest);
      state.Events.push(action.payload);
    });

    builder.addCase(UpdateEventAsync.pending, (state) => handlePending(state.UpdateEventRequest));
    builder.addCase(UpdateEventAsync.rejected, (state, { payload }) => handleRejected(state.UpdateEventRequest, payload as string));
    builder.addCase(UpdateEventAsync.fulfilled, (state, action) => {
      handleSuccess(state.UpdateEventRequest);
      state.Events = state.Events.map(g => g.id === action.payload.id ? action.payload : g);
    });

    builder.addCase(AssignEventMaterialsAsync.pending, (state) => handlePending(state.AssignEventMaterialsRequest));
    builder.addCase(AssignEventMaterialsAsync.rejected, (state, { payload }) => handleRejected(state.AssignEventMaterialsRequest, payload as string));
    builder.addCase(AssignEventMaterialsAsync.fulfilled, (state, action) => {
      handleSuccess(state.AssignEventMaterialsRequest);
      state.Events = state.Events.map(g => g.id === action.payload.id ? action.payload : g);
    });

    builder.addCase(DeleteEventAsync.pending, (state) => handlePending(state.DeleteEventRequest));
    builder.addCase(DeleteEventAsync.rejected, (state, { payload }) => handleRejected(state.DeleteEventRequest, payload as string));
    builder.addCase(DeleteEventAsync.fulfilled, (state, action) => {
      handleSuccess(state.DeleteEventRequest);
      state.Events = state.Events.filter(g => g.id !== action.payload);
    });
  },
});

export const selectEventById = (id: number | null) => (state: RootState) => {
  if (id === null)
    return null;
  return state.events.Events.find(g => g.id === id) || null;
};

export const selectEventsState = (state: RootState) => {
  return {
    events: state.events.Events,
    ...state.events.GetEventsRequest
  }
};

export const selectCreateEventState = (state: RootState) => state.events.CreateEventRequest;
export const selectUpdateEventState = (state: RootState) => state.events.UpdateEventRequest;
export const selectAssignEventMaterialsState = (state: RootState) => state.events.AssignEventMaterialsRequest;

export const useEvents = () => {
  var state = useAppSelector(selectEventsState);
  var dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetEventsAsync());
  }, [dispatch])

  return state
}

export default EventsSlice.reducer;