import { Alert, AlertIcon, Box, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  CreateEventAsync,
  selectCreateEventState,
  selectEventById,
  selectUpdateEventState,
  UpdateEventAsync,
} from '../../redux/eventsSlice'
import CustomModal from '../CustomModal'
import CustomCheckbox from '../form/inputs/CustomCheckbox'
import InputField, { InputFieldType } from '../form/inputs/InputField'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  eventId: number | null
}
const CreateUpdateEventModal = (props: Props) => {
  const [title, setTitle] = React.useState<string | null>(null)
  const [titleEn, setTitleEn] = React.useState<string | null>(null)
  const [description, setDescription] = React.useState<string | null>(null)
  const [descriptionEn, setDescriptionEn] = React.useState<string | null>(null)
  // const [isPubliclyVisible, setIsPubliclyVisible] = React.useState(true)
  const [freeOfCharge, setFreeOfCharge] = React.useState(true)

  const [errorMode, setErrorMode] = useState(false)

  const createEventState = useAppSelector(selectCreateEventState)
  const updateEventState = useAppSelector(selectUpdateEventState)
  const eventToUpdate = useAppSelector(selectEventById(props.eventId))
  const dispatch = useAppDispatch()
  const isValid = true // nothing is required for now

  useEffect(() => {
    if (!props.isOpen || !eventToUpdate) return

    setTitle(eventToUpdate.title)
    setTitleEn(eventToUpdate.titleEn)
    setDescription(eventToUpdate.description)
    setDescriptionEn(eventToUpdate.descriptionEn)
    setFreeOfCharge(eventToUpdate.freeOfCharge)
    // setIsPubliclyVisible(eventToUpdate.isPubliclyVisible)
  }, [props.isOpen, eventToUpdate])

  useEffect(() => {
    if (props.isOpen) return
    setTitle(null)
    setTitleEn(null)
    setDescription(null)
    setDescriptionEn(null)
    setFreeOfCharge(true)
    // setIsPubliclyVisible(true)
    setErrorMode(false)
  }, [props.isOpen])

  const handleFormSubmit = () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)

    if (!!eventToUpdate) {
      dispatch(
        UpdateEventAsync({
          id: eventToUpdate.id,
          body: {
            title,
            titleEn,
            description,
            descriptionEn,
            freeOfCharge,
            // isPubliclyVisible,
          },
        })
      ).then(
        (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
      )
    } else {
      dispatch(
        CreateEventAsync({
          title,
          titleEn,
          description,
          descriptionEn,
          freeOfCharge,
          // isPubliclyVisible,
        })
      ).then(
        (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
      )
    }
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={
        createEventState.status === 'loading' ||
        updateEventState.status === 'loading'
      }
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <InputField
              label="Titel"
              name="eventTitle"
              type={InputFieldType.text}
              onChangeHandler={setTitle}
              value={title ?? ''}
              placeholder="Yoga forløb"
            />

            <InputField
              label="Titel (engelsk)"
              name="eventTitleEn"
              type={InputFieldType.text}
              onChangeHandler={setTitleEn}
              value={titleEn ?? ''}
              placeholder="Yoga course"
            />

            <InputField
              label="Beskrivelse"
              name="eventDescription"
              type={InputFieldType.textarea}
              onChangeHandler={setDescription}
              value={description ?? ''}
              placeholder="Et mega fedt forløb.."
            />
            <InputField
              label="Beskrivelse (engelsk)"
              name="eventDescriptionEn"
              type={InputFieldType.textarea}
              onChangeHandler={setDescriptionEn}
              value={descriptionEn ?? ''}
              placeholder="A great course.."
            />

            {/* <CustomCheckbox
              label="Synlig for alle"
              name="isPubliclyVisible"
              tooltip="Hvorvidt alle brugere skal kunne se denne i listen over kurser. Dette har dog ingen indflydelse på om man kan tilgå kurset."
              onChangeHandler={setIsPubliclyVisible}
              isChecked={isPubliclyVisible}
            /> */}

            <CustomCheckbox
              label="Gratis"
              name="freeOfCharge"
              onChangeHandler={setFreeOfCharge}
              isChecked={freeOfCharge}
              tooltip="Vælg hvis forløbet er gratis. Forløbet og tilhørende materialer vil kunne tilgås i appen uden at være logget ind og have købt adgang."
            />

            {(createEventState.status === 'failed' ||
              updateEventState.status === 'failed') && (
              <Alert status="error">
                <AlertIcon />
                {createEventState.errorMessage || updateEventState.errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </CustomModal>
  )
}

export default CreateUpdateEventModal
