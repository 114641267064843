import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { createCCMindApiClient } from '../api/ApiClientFactory';
import { ApiError, ApiErrorResponse, NewsItemResponse, UpdateNewsItemRequest } from '../api/generated';
import { useAppDispatch, useAppSelector } from '../hooks/hooks';
import { handleRejected, handlePending, StatusSliceBase, genericApiErrorMessage, initialStateBase, handleSuccess } from './sliceHelper';
import { RootState } from './store';

export interface NewsItemsState {
  NewsItems: NewsItemResponse[];
  GetNewsItemsRequest: StatusSliceBase;
  DeleteNewsItemRequest: StatusSliceBase;
  UpdateNewsItemRequest: StatusSliceBase;
  CreateNewsItemRequest: StatusSliceBase;
  UploadNewsItemPictureRequest: StatusSliceBase;
}
const initialState: NewsItemsState = {
  NewsItems: [],
  GetNewsItemsRequest: { ...initialStateBase },
  UpdateNewsItemRequest: { ...initialStateBase },
  DeleteNewsItemRequest: { ...initialStateBase },
  CreateNewsItemRequest: { ...initialStateBase },
  UploadNewsItemPictureRequest: { ...initialStateBase },
};

export const GetNewsItemsAsync = createAsyncThunk(
  'newsItems/getNewsItems',
  async (_, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().newsItems.getNewsItems()
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  },
  { condition: (_, { getState }) => (getState() as RootState).newsItems.GetNewsItemsRequest.status !== "loading" });

export const CreateNewsItemAsync = createAsyncThunk(
  'newsItems/createNewsItem',
  async (obj: UpdateNewsItemRequest, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().newsItems.createNewsItem(obj)
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const UpdateNewsItemAsync = createAsyncThunk(
  'newsItems/updateNewsItem',
  async (obj: { id: number, body: UpdateNewsItemRequest }, { rejectWithValue }) => {
    try {
      return await createCCMindApiClient().newsItems.updateNewsItem(obj.id, obj.body)
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const UploadNewsItemPictureAsync = createAsyncThunk(
  'newsItems/uploadNewsItemPicture',
  async (obj: { id: number, file: Blob }, { rejectWithValue }) => {
    try {
      var response = await createCCMindApiClient().newsItems.uploadNewsItemPic(obj.id, { file: obj.file })
      return response
    } catch (err) {
      var error = (err as ApiError).body as ApiErrorResponse
      var translations: any = {
        50101: "Følgende filtyper er tilladt: jpg, jpeg, png, tif"
      }
      return rejectWithValue(translations[error.errorCode] || genericApiErrorMessage);
    }
  });

export const DeleteNewsItemAsync = createAsyncThunk(
  'newsItems/deleteNewsItem',
  async (id: number, { rejectWithValue }) => {
    try {
      await createCCMindApiClient().newsItems.deleteNewsItem(id)
      return id;
    } catch (err) {
      return rejectWithValue(genericApiErrorMessage);
    }
  });

export const NewsItemsSlice = createSlice({
  name: 'NewsItems',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetNewsItemsAsync.pending, (state) => handlePending(state.GetNewsItemsRequest));
    builder.addCase(GetNewsItemsAsync.rejected, (state, { payload }) => handleRejected(state.GetNewsItemsRequest, payload as string));
    builder.addCase(GetNewsItemsAsync.fulfilled, (state, action) => {
      handleSuccess(state.GetNewsItemsRequest);
      state.NewsItems = action.payload;
    });

    builder.addCase(CreateNewsItemAsync.pending, (state) => handlePending(state.CreateNewsItemRequest));
    builder.addCase(CreateNewsItemAsync.rejected, (state, { payload }) => handleRejected(state.CreateNewsItemRequest, payload as string));
    builder.addCase(CreateNewsItemAsync.fulfilled, (state, action) => {
      handleSuccess(state.CreateNewsItemRequest);
      state.NewsItems.push(action.payload);
    });

    builder.addCase(UpdateNewsItemAsync.pending, (state) => handlePending(state.UpdateNewsItemRequest));
    builder.addCase(UpdateNewsItemAsync.rejected, (state, { payload }) => handleRejected(state.UpdateNewsItemRequest, payload as string));
    builder.addCase(UpdateNewsItemAsync.fulfilled, (state, action) => {
      handleSuccess(state.UpdateNewsItemRequest);
      state.NewsItems = state.NewsItems.map(m => m.id === action.payload.id ? action.payload : m);
    });

    builder.addCase(UploadNewsItemPictureAsync.pending, (state) => handlePending(state.UploadNewsItemPictureRequest));
    builder.addCase(UploadNewsItemPictureAsync.rejected, (state, { payload }) => handleRejected(state.UploadNewsItemPictureRequest, payload as string));
    builder.addCase(UploadNewsItemPictureAsync.fulfilled, (state, action) => {
      handleSuccess(state.UploadNewsItemPictureRequest);
      state.NewsItems = state.NewsItems.map(m => m.id === action.payload.id ? action.payload : m);
    });

    builder.addCase(DeleteNewsItemAsync.pending, (state) => handlePending(state.DeleteNewsItemRequest));
    builder.addCase(DeleteNewsItemAsync.rejected, (state, { payload }) => handleRejected(state.DeleteNewsItemRequest, payload as string));
    builder.addCase(DeleteNewsItemAsync.fulfilled, (state, action) => {
      handleSuccess(state.DeleteNewsItemRequest);
      state.NewsItems = state.NewsItems.filter(m => m.id !== action.payload);
    });
  },
});

export const useNewsItems = () => {
  var state = useAppSelector(selectNewsItemsState);
  var dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(GetNewsItemsAsync());
  }, [dispatch])

  return state
}

export const selectNewsItemsState = (state: RootState) => {
  return {
    newsItems: state.newsItems.NewsItems,
    ...state.newsItems.GetNewsItemsRequest
  }
};
export const selectUpdateNewsItemState = (state: RootState) => state.newsItems.UpdateNewsItemRequest;
export const selectUploadNewsItemPictureState = (state: RootState) => state.newsItems.UploadNewsItemPictureRequest;
export const selectCreateNewsItemState = (state: RootState) => state.newsItems.CreateNewsItemRequest;
export const selectDeleteNewsItemState = (state: RootState) => state.newsItems.DeleteNewsItemRequest;
export const selectNewsItemById = (id: number | null) => (state: RootState) => state.newsItems.NewsItems.find(m => m.id === id) || null;

export default NewsItemsSlice.reducer;