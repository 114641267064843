import {
  Alert,
  AlertIcon,
  Box,
  Flex,
  Heading,
  Text,
  VStack,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { ReactSortable } from 'react-sortablejs'
import { MaterialResponse } from '../../api/generated'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  AssignEventMaterialsAsync,
  selectAssignEventMaterialsState,
  selectEventById,
} from '../../redux/eventsSlice'
import { useMaterials } from '../../redux/materialsSlice'
import CustomModal from '../CustomModal'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  eventId: number | null
}
const AssignEventMaterialsModal = ({ isOpen, ...props }: Props) => {
  const [chosenMaterials, setChosenMaterials] = useState<MaterialResponse[]>([])
  const [availableMaterials, setAvailableMaterials] = useState<
    MaterialResponse[]
  >([])
  const [errorMode, setErrorMode] = useState(false)

  const { materials: allMaterials, ...materialsState } = useMaterials()
  const thisEvent = useAppSelector(selectEventById(props.eventId))
  const assignEventMatsState = useAppSelector(selectAssignEventMaterialsState)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isOpen) return
    setChosenMaterials([])
    setAvailableMaterials([])
  }, [isOpen])

  useEffect(() => {
    if (!isOpen) return

    var alreadyAssignedMats = thisEvent?.materials.map((mat) => mat) ?? []

    setChosenMaterials(
      alreadyAssignedMats
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((mat) => mat.material)
    )
  }, [thisEvent, isOpen])

  useEffect(() => {
    setAvailableMaterials(
      allMaterials.filter(
        (x) => !chosenMaterials.map((x) => x.id).includes(x.id)
      )
    )
  }, [allMaterials, chosenMaterials])

  const isValid =
    materialsState.status !== 'loading' &&
    thisEvent != null &&
    assignEventMatsState.status !== 'loading'

  const handleFormSubmit = () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)
    dispatch(
      AssignEventMaterialsAsync({
        id: props.eventId!,
        materialIds: chosenMaterials.map((x) => x.id),
      })
    ).then(
      (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
    )
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={assignEventMatsState.status === 'loading'}
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <VStack spacing={3}>
            <Flex width={'100%'} gap={4}>
              <Box flexBasis={'100%'}>
                <Heading as="h2" size="sm" fontWeight={500}>
                  Tilgængelige materialer
                </Heading>
                <Text color="gray.500">
                  Du kan trække disse over til tabellen til højre
                </Text>
                <ReactSortable
                  style={{ minHeight: '250px', paddingTop: '10px' }}
                  group={'shared-group-1'}
                  tag="div"
                  list={availableMaterials.map((x) => ({ ...x }))}
                  setList={(list) => setAvailableMaterials(list)}
                  sort={false}
                >
                  {availableMaterials.map((material) => (
                    <div key={material.id}>
                      <Box
                        bg="gray.400"
                        w="100%"
                        p={2}
                        color="white"
                        marginBottom={1}
                        cursor="move"
                      >
                        <Text>{material.title}</Text>
                      </Box>
                    </div>
                  ))}
                </ReactSortable>
              </Box>
              <Box flexBasis="100%">
                <Heading as="h2" size="sm" fontWeight={500}>
                  Valgte materialer for dette forløb
                </Heading>
                <Text color="gray.500">
                  Du kan rykke rundt på dem for at ændre rækkefølgen hvorpå de
                  vises i appen.
                </Text>
                <ReactSortable
                  style={{ minHeight: '250px', paddingTop: '10px' }}
                  group={'shared-group-1'}
                  tag="div"
                  list={chosenMaterials.map((x) => ({ ...x }))}
                  setList={(list) => setChosenMaterials(list)}
                >
                  {chosenMaterials.map((material, i) => (
                    <div key={material.id}>
                      <Box
                        bg="blue.400"
                        w="100%"
                        p={2}
                        color="white"
                        marginBottom={1}
                        cursor="move"
                      >
                        <Text>
                          <strong>{i} - </strong>
                          {material.title}
                        </Text>
                      </Box>
                    </div>
                  ))}
                </ReactSortable>
              </Box>
            </Flex>
          </VStack>

          {assignEventMatsState.status === 'failed' && (
            <Alert status="error">
              <AlertIcon />
              {assignEventMatsState.errorMessage ||
                assignEventMatsState.errorMessage}
            </Alert>
          )}
        </form>
      </Box>
    </CustomModal>
  )
}

export default AssignEventMaterialsModal
