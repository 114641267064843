import { Route, Routes } from "react-router-dom";
import { appRoutes } from "./appRoutes";
import Header from "./components/header/Header";
import { useAppSelector } from "./hooks/hooks";
import Accounts from "./pages/Accounts";
import Contents from "./pages/Contents";
import Events from "./pages/Events";
import ForgotLoginPassword from "./pages/ForgotLoginPassword";
import Groups from "./pages/Groups";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Materials from "./pages/Materials";
import NewsItems from "./pages/NewsItems";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import UpdateLoginPassword from "./pages/UpdateLoginPassword";
import { selectIsLoggedIn } from "./redux/loginSlice";

function App() {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  return (
    <div className="App">
      {isLoggedIn && <Header />}
      <Routes>
        <Route path={appRoutes.home} element={<Home />} />
        <Route path={appRoutes.accounts} element={<Accounts />} />
        <Route path={appRoutes.groups} element={<Groups />} />
        <Route path={appRoutes.contents} element={<Contents />} />
        <Route path={appRoutes.events} element={<Events />} />
        <Route path={appRoutes.materials} element={<Materials />} />
        <Route path={appRoutes.newsItems} element={<NewsItems />} />
        <Route path={appRoutes.login} element={<Login />} />
        <Route path={appRoutes.privacyPolicy} element={<PrivacyPolicy />} />
        <Route path={appRoutes.forgotLoginPassword} element={<ForgotLoginPassword />} />
        <Route path={appRoutes.updateLoginPassword} element={<UpdateLoginPassword />} />
      </Routes>
    </div>
  );
}

export default App;
