import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import groupsReducer from './groupsSlice';
import materialsReducer from './materialsSlice';
import eventsReducer from './eventsSlice';
import accountsReducer from './accountsSlice';
import contentsReducer from './contentsSlice';
import newsItemsReducer from './newsItemsSlice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    groups: groupsReducer,
    materials: materialsReducer,
    events: eventsReducer,
    accounts: accountsReducer,
    contents: contentsReducer,
    newsItems: newsItemsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
