import { QuestionIcon } from '@chakra-ui/icons'
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { localStorageAuthUserKey } from '../../../redux/loginSlice'
import download from "downloadjs"

interface Props {
  label: string
  name: string
  isRequired?: boolean
  isDisabled?: boolean
  displayErrorIfInvalid?: boolean
  errorText?: string
  existingFileName?: string
  existingFileUrl?: string
  tooltip?: string
  onChangeHandler: (file: Blob | null) => void
  onIsValidChangeHandler?: (isValid: boolean) => void
}

const CustomFileInput = ({ onIsValidChangeHandler, ...props }: Props) => {
  const [file, _setFile] = useState<Blob | null>(null)

  const setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    var file = event.target.files?.length === 0 ? null : event.target.files![0]
    _setFile(file)
    props.onChangeHandler(file)
  }

  const isValid = !props.isRequired || file !== null

  useEffect(() => {
    onIsValidChangeHandler && onIsValidChangeHandler(isValid)
  }, [isValid, onIsValidChangeHandler])

  return (
    <>
      <FormControl
        isInvalid={props.displayErrorIfInvalid && !isValid}
        isRequired={props.isRequired}
        isDisabled={props.isDisabled}
      >
        <FormLabel htmlFor={props.name}>
          {props.label}{' '}
          {props.tooltip && (
            <Tooltip label={props.tooltip}>
              <QuestionIcon />
            </Tooltip>
          )}
        </FormLabel>
        {props.existingFileName && (
          <>
            <Text fontSize="sm">
              <strong>Eksisterende fil: </strong>
              {props.existingFileUrl ? (
                <DownloadLink
                  url={props.existingFileUrl}
                  fileName={props.existingFileName}
                />
              ) : (
                <span>{props.existingFileName}</span>
              )}
              {' '}
              <Tooltip
                label={
                  'Den tidligere fil vil slettes såfremt en ny fil vælges.'
                }
              >
                <QuestionIcon />
              </Tooltip>
            </Text>
          </>
        )}
        <Input
          id={props.name}
          type="file"
          multiple={false}
          onChange={setFile}
        />
        {props.displayErrorIfInvalid && !isValid && (
          <FormErrorMessage>
            {props.errorText ?? 'Feltet er ugyldigt'}
          </FormErrorMessage>
        )}
      </FormControl>
    </>
  )
}

function DownloadLink(props: { url: string; fileName: string }) {
  var token = localStorage.getItem(localStorageAuthUserKey)

  const downloadStuff = async (e: any) => {
    e.preventDefault()
    const options = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    fetch(props.url, options)
      .then((response) => response.blob())
      .then((blob) => {
        download(blob, props.fileName)
      })
  }

  return <Link onClick={downloadStuff}>{props.fileName}</Link>
}

export default CustomFileInput
