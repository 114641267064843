import {
  Box,
  Container,
  Heading,
  Stack,
  Spinner,
  Alert,
  AlertIcon,
  HStack,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { Column } from 'react-table'
import { MaterialResponse } from '../api/generated'
import CustomAlertDialog from '../components/CustomAlertDialog'
import CustomButton from '../components/CustomButton'
import CreateUpdateMaterialModal from '../components/modal/CreateUpdateMaterialModal'
import CustomTable, { createDefaultColumn } from '../components/table/CustomTable'
import { useAppDispatch, useRequireAuth } from '../hooks/hooks'
import { DeleteMaterialAsync, useMaterials } from '../redux/materialsSlice'

const Materials = () => {
  useRequireAuth()
  const dispatch = useAppDispatch()
  const { materials, status, lastUpdated, errorMessage } = useMaterials()

  const [deleteIsOpen, setDeleteIsOpen] = React.useState(false)
  const [createIsOpen, setCreateIsOpen] = React.useState(false)
  const [updateIsOpen, _setUpdateIsOpen] = React.useState(false)
  const [materialToDelete, setMaterialToDelete] =
    React.useState<MaterialResponse | null>(null)
  const [materialIdToUpdate, setMaterialIdToUpdate] = React.useState<
    number | null
  >(null)

  const deleteClickHandler = (obj: MaterialResponse) => {
    setDeleteIsOpen(true)
    setMaterialToDelete(obj)
  }

  const updateClickHandler = (obj: MaterialResponse) => {
    _setUpdateIsOpen(true)
    setMaterialIdToUpdate(obj.id)
  }

  const setUpdateIsOpen = (value: boolean) => {
    setMaterialIdToUpdate(null)
    _setUpdateIsOpen(value)
  }

  const deleteSubmitHandler = () => {
    dispatch(DeleteMaterialAsync(materialToDelete!.id))
    setDeleteIsOpen(false)
    setMaterialToDelete(null)
  }

  const columns: Column<MaterialResponse>[] = [
    createDefaultColumn({ header: 'Id', accessor: (x) => x.id }),
    createDefaultColumn({ header: 'Titel', accessor: (x) => x.title || x.titleEn }),
    createDefaultColumn({ header: 'Lyd/video-fil', accessor: (x) => x.fileName || x.fileNameEn }),
    // createDefaultColumn({ header: 'Gratis', accessor: (x) => x.freeOfCharge ? "Ja" : "Nej" }),
    createDefaultColumn({ header: 'Indtalt af', accessor: (x) => x.author }),
    createDefaultColumn({
      header: 'Oprettet',
      accessor: (x) => new Date(x.createdDate),
      sortType: "datetime",
      Cell: ({ value }: { value: Date }) => value.toLocaleString(),
    }),
    createDefaultColumn({
      header: 'Handlinger',
      accessor: (x) => x,
      disableSortBy: true,
      Cell: (cellProps) => (
        <>
          <CustomButton
            title="Ændr"
            color="green"
            onClickHandler={() => updateClickHandler(cellProps.value)}
          />
          <CustomButton
            title="Slet"
            color="red"
            onClickHandler={() => deleteClickHandler(cellProps.value)}
          />
        </>
      ),
    }),
  ]

  return (
    <Container paddingTop="50px" maxW={'150ch'}>
      <Stack spacing={10}>
        <Heading as="h1" size="xl">
          Materialer
        </Heading>

        <Box>
          <HStack justifyContent={"space-between"}>
            <CustomButton
              title="Opret materiale"
              onClickHandler={() => setCreateIsOpen(true)}
              color="green"
            />
            {!!lastUpdated && (
              <Text color="gray.500">
                Sidst opdateret: {new Date(lastUpdated).toLocaleString()}
              </Text>
            )}
          </HStack>
        </Box>
        {!!errorMessage ? (
          <Alert status="error">
            <AlertIcon />
            {errorMessage}
          </Alert>
        ) : status === 'loading' && !lastUpdated ? (
          <Spinner size="lg" />
        ) : (
          <CustomTable columns={columns} data={materials} />
        )}
      </Stack>
      <CreateUpdateMaterialModal
        title="Opret materiale"
        content={`Opret nyt materiale. Efter dette materiale er oprettet kan det blive tildelt til forløb. Materialer kan tilhøre flere forskellige forløb.`}
        isOpen={createIsOpen}
        materialId={null}
        onClose={() => setCreateIsOpen(false)}
      />
      <CreateUpdateMaterialModal
        title="Opdater materiale"
        content={`Opdater eksisterende materiale.`}
        isOpen={updateIsOpen}
        materialId={materialIdToUpdate}
        onClose={() => setUpdateIsOpen(false)}
      />
      <CustomAlertDialog
        title="Er du sikker på at du vil slette?"
        content={`Materialet ${materialToDelete?.title ?? ""} vil slettes permanent og kan ikke gendannes.`}
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onSubmit={deleteSubmitHandler}
      />
    </Container>
  )
}

export default Materials
