import { Container, Heading, Stack, Text } from '@chakra-ui/react'
import ForgotLoginPasswordForm from '../components/form/ForgotLoginPasswordForm'

const ForgotLoginPassword = () => {
  return (
    <Container paddingTop="150px" maxW={'60ch'}>
      <Stack spacing={10}>
        <Stack spacing={4}>
          <Heading as="h1" size="xl">
            Glemt adgangskode
          </Heading>
          <Text color="gray.500">
            Udfyld formularen og du vil modtage en email med et link til at oprette en ny adgangskode. <br/>
            Tjek evt. din spam-folder hvis emailen ikke dukker op i din indbakke.
          </Text>
        </Stack>
        <ForgotLoginPasswordForm></ForgotLoginPasswordForm>
      </Stack>
    </Container>
  )
}

export default ForgotLoginPassword
