import { Alert, AlertIcon, Box, Stack } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { NewsItemResponse } from "../../api/generated"
import { useAppDispatch, useAppSelector } from "../../hooks/hooks"
import {
  CreateNewsItemAsync,
  selectCreateNewsItemState,
  selectNewsItemById,
  selectUpdateNewsItemState,
  selectUploadNewsItemPictureState,
  UpdateNewsItemAsync,
  UploadNewsItemPictureAsync,
} from "../../redux/newsItemsSlice"
import CustomModal from "../CustomModal"
import CustomFileInput from "../form/inputs/CustomFileInput"
import InputField, { InputFieldType } from "../form/inputs/InputField"
import InputSubmitButton from "../form/inputs/InputSubmitButton"

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  newsItemId: number | null
}
const CreateUpdateNewsItemModal = (props: Props) => {
  const [title, setTitle] = React.useState<string | null>(null)
  const [titleEn, setTitleEn] = React.useState<string | null>(null)
  const [description, setDescription] = React.useState<string | null>(null)
  const [descriptionEn, setDescriptionEn] = React.useState<string | null>()
  const [chosenPicture, setChosenPicture] = React.useState<Blob | null>(null)

  const [errorMode, setErrorMode] = useState(false)

  const createNewsItemState = useAppSelector(selectCreateNewsItemState)
  const updateNewsItemState = useAppSelector(selectUpdateNewsItemState)
  const uploadPicState = useAppSelector(selectUploadNewsItemPictureState)
  const newsItemToUpdate = useAppSelector(selectNewsItemById(props.newsItemId))
  const dispatch = useAppDispatch()
  const isValid = true // nothing required so far

  useEffect(() => {
    if (props.isOpen) return
    setTitle(null)
    setTitleEn(null)
    setDescription(null)
    setDescriptionEn(null)
    setChosenPicture(null)

    setErrorMode(false)
  }, [props.isOpen])

  useEffect(() => {
    if (!props.isOpen || !newsItemToUpdate) return
    setTitle(newsItemToUpdate.title)
    setTitleEn(newsItemToUpdate.titleEn)
    setDescription(newsItemToUpdate.description)
    setDescriptionEn(newsItemToUpdate.descriptionEn)
  }, [newsItemToUpdate, props.isOpen])

  const uploadFiles = async (newsItemId: number): Promise<boolean> => {
    var pictureResponse = !!chosenPicture
      ? await dispatch(
          UploadNewsItemPictureAsync({
            id: newsItemId,
            file: chosenPicture!,
          })
        )
      : undefined

    return (
      !pictureResponse || pictureResponse.meta.requestStatus === "fulfilled"
    )
  }

  const handleFormSubmit = async () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)
    if (!!newsItemToUpdate) {
      dispatch(
        UpdateNewsItemAsync({
          id: newsItemToUpdate.id,
          body: {
            title,
            titleEn,
            description,
            descriptionEn,
          },
        })
      ).then(async (value) => {
        if (value.meta.requestStatus === "fulfilled") {
          var uploadsCompleted = await uploadFiles(newsItemToUpdate.id)
          uploadsCompleted === true && props.onClose()
        }
      })
    } else
      dispatch(
        CreateNewsItemAsync({
          title,
          titleEn,
          description,
          descriptionEn,
        })
      ).then(async (value) => {
        if (value.meta.requestStatus === "fulfilled") {
          var newsItemId = (value.payload as NewsItemResponse).id
          var uploadsCompleted = await uploadFiles(newsItemId)
          uploadsCompleted === true && props.onClose()
        }
      })
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={
        createNewsItemState.status === "loading" ||
        updateNewsItemState.status === "loading" ||
        uploadPicState.status === "loading"
      }
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <InputField
              label="Titel"
              name="newsItemTitle"
              type={InputFieldType.text}
              onChangeHandler={setTitle}
              value={title || ""}
              placeholder=""
            />

            <InputField
              label="Titel (engelsk)"
              name="newsItemTitleEn"
              type={InputFieldType.text}
              onChangeHandler={setTitleEn}
              value={titleEn || ""}
              placeholder=""
            />

            <InputField
              label="Beskrivelse"
              name="newsItemDescription"
              type={InputFieldType.textareaEditor}
              onChangeHandler={setDescription}
              value={description || ""}
              placeholder=""
            />

            <InputField
              label="Beskrivelse (engelsk)"
              name="newsItemDescriptionEm"
              type={InputFieldType.textareaEditor}
              onChangeHandler={setDescriptionEn}
              value={descriptionEn || ""}
              placeholder=""
            />

            <CustomFileInput
              label="Billede-fil"
              existingFileName={
                newsItemToUpdate?.picture?.split("/").pop() ?? undefined
              }
              existingFileUrl={newsItemToUpdate?.picture ?? undefined}
              name="newsItemPicture"
              tooltip="Vælg et billede som skal vises på siden for den pågældende nyhed. Filen skal være af typen png, jpg eller tif. Billedet skal være i landscape-mode"
              onChangeHandler={setChosenPicture}
              isDisabled={!title && !titleEn}
            />

            {[
              createNewsItemState.status,
              updateNewsItemState.status,
              uploadPicState.status,
            ].includes("failed") && (

              <Alert status="error">
                <AlertIcon />
                {createNewsItemState.errorMessage ||
                  updateNewsItemState.errorMessage ||
                  uploadPicState.errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </CustomModal>
  )
}

export default CreateUpdateNewsItemModal
