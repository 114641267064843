import {
  Alert,
  AlertIcon,
  Box,
  Container,
  Heading,
  HStack,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react"
import React from "react"
import { Column } from "react-table"
import { AccountResponse } from "../api/generated"
import CustomAlertDialog from "../components/CustomAlertDialog"
import CustomButton from "../components/CustomButton"
import CreateBulkAccountsModal from "../components/modal/CreateBulkAccountsModal"
import CreateUpdateAccountModal from "../components/modal/CreateUpdateAccountModal"
import CustomTable, {
  createDefaultColumn,
} from "../components/table/CustomTable"
// import UpdateAccountModal from '../components/form/UpdateAccountModal'
import { useAppDispatch, useRequireAuth } from "../hooks/hooks"
import { DeleteAccountAsync, useAccounts } from "../redux/accountsSlice"
import { useGroups } from "../redux/groupsSlice"

const Accounts = () => {
  useRequireAuth()
  const dispatch = useAppDispatch()
  const { accounts, status, lastUpdated, errorMessage } = useAccounts()
  const { groups } = useGroups()

  const [deleteIsOpen, setDeleteIsOpen] = React.useState(false)
  const [createBulkIsOpen, setCreateBulkIsOpen] = React.useState(false)
  const [createIsOpen, setCreateIsOpen] = React.useState(false)
  const [updateIsOpen, setUpdateIsOpen] = React.useState(false)
  const [accountToDelete, setAccountToDelete] =
    React.useState<AccountResponse | null>(null)
  const [accountIdToUpdate, setAccountIdToUpdate] = React.useState<
    number | null
  >(null)

  const deleteClickHandler = (obj: AccountResponse) => {
    setDeleteIsOpen(true)
    setAccountToDelete(obj)
  }

  const updateClickHandler = (obj: AccountResponse) => {
    setUpdateIsOpen(true)
    setAccountIdToUpdate(obj.id)
  }

  const deleteSubmitHandler = () => {
    dispatch(DeleteAccountAsync(accountToDelete!.id))
    setDeleteIsOpen(false)
    setAccountToDelete(null)
  }

  // const uniqueGroups = [...new Set(groups.map((g) => g.name))]

  const columns: Column<AccountResponse>[] = [
    createDefaultColumn({ header: "id", accessor: (x) => x.id }),
    createDefaultColumn({ header: "Fulde navn", accessor: (x) => x.fullName }),
    createDefaultColumn({ header: "Email", accessor: (x) => x.email }),
    createDefaultColumn({
      header: "Motivation",
      accessor: (x) => x.motivation,
    }),
    createDefaultColumn({
      header: "Gruppe",
      accessor: (x) => x,
      disableSortBy: false,
      // filter: 'includes', // this doesnt work after we changed to multiple groups
      // Filter: (props) =>
      //   SelectColumnFilter({
      //     ...props,
      //     options: uniqueGroups,
      //   }),
      Cell: (cellProps) => {
        const groupNames = groups
          .filter((x) => cellProps.row.original.groupIds.includes(x.id))
          .map((x) => x.name)
        return <>{groupNames.join(", ")}</>
      },
    }),
    createDefaultColumn({
      header: "Admin?",
      accessor: (x) => (x.isAdmin ? "Ja" : "Nej"),
    }),
    createDefaultColumn({
      header: "Handlinger",
      accessor: (x) => x,
      disableSortBy: true,
      Cell: (cellProps) => (
        <>
          <CustomButton
            title="Ændr"
            color="green"
            onClickHandler={() => updateClickHandler(cellProps.value)}
          />
          <CustomButton
            title="Slet"
            color="red"
            onClickHandler={() => deleteClickHandler(cellProps.value)}
          />
        </>
      ),
    }),
  ]

  return (
    <Container paddingTop="50px" maxW={"150ch"}>
      <Stack spacing={10}>
        <Heading as="h1" size="xl">
          Brugere
        </Heading>
        <Box>
          <HStack justifyContent={"space-between"}>
            <HStack>
              <CustomButton
                title="Opret bruger"
                onClickHandler={() => setCreateIsOpen(true)}
                color="green"
              />
              <CustomButton
                title="Masse-opret brugere"
                onClickHandler={() => setCreateBulkIsOpen(true)}
                color="green"
              />
            </HStack>
            {!!lastUpdated && (
              <Text color="gray.500">
                Sidst opdateret: {new Date(lastUpdated).toLocaleString()}
              </Text>
            )}
          </HStack>
        </Box>
        {!!errorMessage && (
          <Alert status="error">
            <AlertIcon />
            {errorMessage}
          </Alert>
        )}
        {status === "loading" && !lastUpdated && <Spinner size="lg" />}
        <CustomTable columns={columns} data={accounts} />
      </Stack>
      <CreateUpdateAccountModal
        title="Opret bruger"
        content={`Opret en ny bruger. Brugere tilhører grupper og får adgang til diverse kurser og materialer derigennem. Bemærk at en bruger ikke kan logge ind i administrationspanelet uden administratorrettigheder. En ny-oprettet bruger vil få tilsendt en email med et link til at oprette en adgangskode.`}
        isOpen={createIsOpen}
        onClose={() => setCreateIsOpen(false)}
        accountId={null}
      />
      <CreateBulkAccountsModal
        title="Masse-opret brugere"
        content={`Opret en liste af brugere ud fra email-adresser. Samtlige brugere vil få tilsendt en email med et link til at oprette en adgangskode.`}
        isOpen={createBulkIsOpen}
        onClose={() => setCreateBulkIsOpen(false)}
      />
      <CreateUpdateAccountModal
        title="Updater bruger"
        content={`Opdater en eksisterende bruger. Hvis man ændrer hvilken gruppe brugere tilhører, vil brugere få synkroniseret sine adgange til kurser mm. ved næste login i appen.`}
        isOpen={updateIsOpen}
        onClose={() => setUpdateIsOpen(false)}
        accountId={accountIdToUpdate}
      />
      <CustomAlertDialog
        title="Er du sikker på at du vil slette?"
        content={`Brugeren ${accountToDelete?.fullName} vil slettes permanent og kan ikke gendannes.`}
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onSubmit={deleteSubmitHandler}
      />
    </Container>
  )
}

export default Accounts
