/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateNewsItemRequest } from '../models/CreateNewsItemRequest';
import type { NewsItemResponse } from '../models/NewsItemResponse';
import type { UpdateNewsItemRequest } from '../models/UpdateNewsItemRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class NewsItemsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new newsItem
     * @param requestBody 
     * @returns NewsItemResponse Created
     * @throws ApiError
     */
    public createNewsItem(
requestBody?: CreateNewsItemRequest,
): CancelablePromise<NewsItemResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/NewsItems',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get newsItems
     * @returns NewsItemResponse Success
     * @throws ApiError
     */
    public getNewsItems(): CancelablePromise<Array<NewsItemResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/NewsItems',
        });
    }

    /**
     * Update an existing newsItem
     * @param id 
     * @param requestBody 
     * @returns NewsItemResponse Created
     * @throws ApiError
     */
    public updateNewsItem(
id: number,
requestBody?: UpdateNewsItemRequest,
): CancelablePromise<NewsItemResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/NewsItems/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>70101</i> - The news item doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Get newsItem
     * @param id 
     * @returns NewsItemResponse Success
     * @throws ApiError
     */
    public getNewsItem(
id: number,
): CancelablePromise<NewsItemResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/NewsItems/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>70101</i> - The news item doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Delete newsItem
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public deleteNewsItem(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/NewsItems/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>70101</i> - The news item doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Update the picture for a newsItem.
 * The previous picture will be permanently deleted.
     * @param id 
     * @param formData 
     * @returns NewsItemResponse Created
     * @throws ApiError
     */
    public uploadNewsItemPic(
id: number,
formData?: {
file?: Blob;
},
): CancelablePromise<NewsItemResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/NewsItems/{id}/uploadPicture',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>70101</i> - The news item doesn't exist</li></ul>`,
                422: `<p>Error codes:</p><ul><li><i>50101</i> - The file extension is not allowed for the operation.</li></ul>`,
            },
        });
    }

}