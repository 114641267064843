import { Container, Heading, Stack, Text } from "@chakra-ui/react"
import UpdateLoginPasswordForm from "../components/form/UpdateLoginPasswordForm"
import { httpHelper } from "../utils/httpHelper"

const UpdateLoginPassword = () => {
  const pwToken = httpHelper.getQueryParam("passwordToken")
  const lang = httpHelper.getQueryParam("lang")
  const template = httpHelper.getQueryParam("template")

  const queryParamsAreValid =
    !!pwToken &&
    ["da", "en"].includes(lang) &&
    ["velkommen", "opdater-adgangskode"].includes(template)

  const isWelcomeTemplate = template === "velkommen"
  const isUpdatePasswordTemplate = template === "opdater-adgangskode"

  const isLangDa = lang === "da"
  const isLangEn = lang === "en"

  const text_heading =
    isLangDa && isWelcomeTemplate
      ? "Opret adgangskode"
      : isLangDa && isUpdatePasswordTemplate
      ? "Opdater adgangskode"
      : isLangEn && isWelcomeTemplate
      ? "Create password"
      : isLangEn && isUpdatePasswordTemplate
      ? "Update password"
      : ""

  const text_subtitle =
    isLangDa && isWelcomeTemplate
      ? "Opret en adgangskode for at kunne logge ind i C&C Mind app'en"
      : isLangDa && isUpdatePasswordTemplate
      ? "Opdater din adgangskode"
      : isLangEn && isWelcomeTemplate
      ? "Create a password to be able to log in to the C&C Mind app"
      : isLangEn && isUpdatePasswordTemplate
      ? "Update your password"
      : ""

  return (
    <Container paddingTop="150px" maxW={"60ch"}>
      {!queryParamsAreValid ? (
        <>
          <Text>Noget gik galt med at indlæse linket</Text>
        </>
      ) : (
        <>
          <Stack spacing={10}>
            <Stack spacing={4}>
              <Heading as="h1" size="xl">
                {text_heading}
              </Heading>
              <Text color="gray.500">{text_subtitle}</Text>
            </Stack>
            <UpdateLoginPasswordForm
              isEnLang={isLangEn}
              resetPasswordToken={pwToken}
            ></UpdateLoginPasswordForm>
          </Stack>
          {/* <Text fontSize="small">
            Log ind som administrator{" "}
            <Link as={ReactRouterLink} color="teal.500" to={appRoutes.login}>
              her
            </Link>
          </Text> */}
        </>
      )}
    </Container>
  )
}

export default UpdateLoginPassword
