import { Alert, AlertIcon, Box, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  CreateGroupAsync,
  selectCreateGroupState,
  selectGroupById,
  selectUpdateGroupState,
  UpdateGroupAsync,
} from '../../redux/groupsSlice'
import CustomModal from '../CustomModal'
import InputField, { InputFieldType } from '../form/inputs/InputField'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  groupId: number | null
}
const CreateUpdateGroupModal = (props: Props) => {
  const [name, setName] = React.useState('')
  const [nameValidated, setNameValidated] = React.useState(false)
  const [errorMode, setErrorMode] = useState(false)

  const createAccState = useAppSelector(selectCreateGroupState)
  const updateAccState = useAppSelector(selectUpdateGroupState)
  const groupToUpdate = useAppSelector(selectGroupById(props.groupId))
  const dispatch = useAppDispatch()
  const isValid = nameValidated

  useEffect(() => {
    if (!props.isOpen || !groupToUpdate) return
    setName(groupToUpdate.name)
  }, [props.isOpen, groupToUpdate])

  useEffect(() => {
    if (props.isOpen) return
    setName('')
    setErrorMode(false)
  }, [props.isOpen])

  const handleFormSubmit = () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)

    if (!!groupToUpdate)
      dispatch(UpdateGroupAsync({ id: groupToUpdate.id, name })).then(
        (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
      )
    else
      dispatch(CreateGroupAsync({ name })).then(
        (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
      )
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={
        createAccState.status === 'loading' ||
        updateAccState.status === 'loading'
      }
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <InputField
              label="Navn"
              name="groupName"
              type={InputFieldType.text}
              onChangeHandler={setName}
              onIsValidChangeHandler={setNameValidated}
              value={name}
              placeholder="Bauhaus"
              displayErrorIfInvalid={errorMode}
              isRequired={true}
            />

            {(createAccState.status === 'failed' ||
              updateAccState.status === 'failed') && (
              <Alert status="error">
                <AlertIcon />
                {createAccState.errorMessage || updateAccState.errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </CustomModal>
  )
}

export default CreateUpdateGroupModal
