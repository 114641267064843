import { Alert, AlertIcon, Box, Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
  selectContentById,
  selectUpdateContentState,
  UpdateContentAsync,
} from '../../redux/contentsSlice'
import CustomModal from '../CustomModal'
import InputField, { InputFieldType } from '../form/inputs/InputField'
import InputSubmitButton from '../form/inputs/InputSubmitButton'

interface Props {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string
  contentId: number | null
}
const UpdateContentModal = (props: Props) => {
  const [content, setContent] = useState('')
  const [contentEn, setContentEn] = useState<string | null>(null)
  const [contentValidated, setContentValidated] = React.useState(false)
  const [contentEnValidated, setContentEnValidated] = React.useState(false)

  const [errorMode, setErrorMode] = useState(false)

  const updateContentState = useAppSelector(selectUpdateContentState)
  const contentToUpdate = useAppSelector(selectContentById(props.contentId))
  const dispatch = useAppDispatch()
  const isValid = contentValidated && contentEnValidated

  useEffect(() => {
    if(!props.isOpen || !contentToUpdate) return

    setContent(contentToUpdate.content)
    setContentEn(contentToUpdate.contentEn)
  }, [props.isOpen, contentToUpdate])

  useEffect(() => {
    if(props.isOpen) return
    setContent('')
    setContentEn(null)
    setErrorMode(false)
  }, [props.isOpen])

  const handleFormSubmit = () => {
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)

    dispatch(
      UpdateContentAsync({
        id: contentToUpdate!.id,
        content,
        contentEn: contentEn ?? undefined,
      })
    ).then(
      (value) => value.meta.requestStatus === 'fulfilled' && props.onClose()
    )
  }

  const SubmitButton = (
    <InputSubmitButton
      disabled={errorMode && !isValid}
      submitting={updateContentState.status === 'loading'}
      title="Udfør"
      onClickHandler={handleFormSubmit}
    />
  )

  return (
    <CustomModal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={props.title}
      content={props.content}
      submitButton={SubmitButton}
    >
      <Box>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack spacing={3}>
            <InputField
              label="Tekst"
              name="content"
              type={InputFieldType.textarea}
              onChangeHandler={setContent}
              onIsValidChangeHandler={setContentValidated}
              value={content}
              displayErrorIfInvalid={errorMode}
              isRequired={true}
            />

            <InputField
              label="Tekst (engelsk)"
              name="contentEn"
              type={InputFieldType.textarea}
              onChangeHandler={setContentEn}
              onIsValidChangeHandler={setContentEnValidated}
              value={contentEn || ''}
              displayErrorIfInvalid={errorMode}
              isRequired={true}
            />

            {updateContentState.status === 'failed' && (
              <Alert status="error">
                <AlertIcon />
                {updateContentState.errorMessage ||
                  updateContentState.errorMessage}
              </Alert>
            )}
          </Stack>
        </form>
      </Box>
    </CustomModal>
  )
}

export default UpdateContentModal
