import { QuestionIcon } from '@chakra-ui/icons'
import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  SimpleGrid,
  Spinner,
  Tooltip,
} from '@chakra-ui/react'
import { useGroups } from '../../../redux/groupsSlice'

interface Props {
  label: string
  name: string
  value: number[]
  tooltip?: string
  displayErrorIfInvalid?: boolean
  isRequired?: boolean
  onChangeHandler: (groupIds: number[]) => void
}

const GroupSelector = (props: Props) => {
  const { groups, status } = useGroups()

  return (
    <>
      {/* render a list of checkbox inputs - one for each group */}
      {status === 'loading' && <Spinner size="sm" />}
      {status === 'idle' && (
        <>
          <FormControl>
            <FormLabel>
              Grupper{' '}
              <Tooltip label="Brugeren vil få adgang til samtlige kurser som de valgte grupper har adgang til">
                <QuestionIcon />
              </Tooltip>
            </FormLabel>
            <SimpleGrid minChildWidth="150px">
              {groups.map((group) => (
                <Box>
                  <Checkbox
                    spacing="3"
                    name={props.name}
                    isChecked={props.value.includes(group.id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        props.onChangeHandler([...props.value, group.id])
                      } else {
                        props.onChangeHandler(
                          props.value.filter((x) => x !== group.id)
                        )
                      }
                    }}
                  >
                    {group.name}
                  </Checkbox>
                </Box>
              ))}
            </SimpleGrid>
          </FormControl>
        </>
      )}
    </>
  )
}

export default GroupSelector
