import {
  Box,
  Container,
  Heading,
  Stack,
  Spinner,
  HStack,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react'
import React from 'react'
import { Column } from 'react-table'
import { EventResponse } from '../api/generated'
import CustomAlertDialog from '../components/CustomAlertDialog'
import CustomButton from '../components/CustomButton'
import AssignEventMaterialsModal from '../components/modal/AssignEventMaterialsModal'
import CreateUpdateEventModal from '../components/modal/CreateUpdateEventModal'
import CustomTable, { createDefaultColumn } from '../components/table/CustomTable'
import { useAppDispatch, useRequireAuth } from '../hooks/hooks'
import { DeleteEventAsync, useEvents } from '../redux/eventsSlice'

const Events = () => {
  useRequireAuth()
  const dispatch = useAppDispatch()
  const { events, status, lastUpdated, errorMessage } = useEvents()

  const [deleteIsOpen, setDeleteIsOpen] = React.useState(false)
  const [createIsOpen, setCreateIsOpen] = React.useState(false)
  const [updateIsOpen, setUpdateIsOpen] = React.useState(false)
  const [assignMatsIsOpen, setAssignMatsIsOpen] = React.useState(false)
  const [eventToEdit, setEventToEdit] = React.useState<EventResponse | null>(
    null
  )

  const deleteClickHandler = (obj: EventResponse) => {
    setDeleteIsOpen(true)
    setEventToEdit(obj)
  }

  const updateClickHandler = (obj: EventResponse) => {
    setUpdateIsOpen(true)
    setEventToEdit(obj)
  }

  const assignMatsClickHandler = (obj: EventResponse) => {
    setAssignMatsIsOpen(true)
    setEventToEdit(obj)
  }

  const deleteSubmitHandler = () => {
    dispatch(DeleteEventAsync(eventToEdit!.id))
    setDeleteIsOpen(false)
    setEventToEdit(null)
  }

  const columns: Column<EventResponse>[] = [
    createDefaultColumn({ header: 'Id', accessor: (x) => x.id }),
    createDefaultColumn({ header: 'Titel', accessor: (x) => x.title || x.titleEn }),
    createDefaultColumn({ header: 'Beskrivelse', accessor: (x) => x.description || x.descriptionEn }),
    createDefaultColumn({ header: 'Gratis', accessor: (x) => (x.freeOfCharge ? 'Ja' : 'Nej')}),
    createDefaultColumn({
      header: 'Oprettet',
      accessor: (x) => new Date(x.createdDate),
      sortType: "datetime",
      Cell: ({ value }: { value: Date }) => value.toLocaleString(),
    }),
    createDefaultColumn({
      header: 'Handlinger',
      accessor: (x) => x,
      disableSortBy: true,
      Cell: (cellProps) => (
        <>
          <CustomButton
            title="Ændr"
            color="green"
            onClickHandler={() => updateClickHandler(cellProps.value)}
          />
          <CustomButton
            title="Tildel materialer"
            color="green"
            onClickHandler={() => assignMatsClickHandler(cellProps.value)}
          />
          <CustomButton
            title="Slet"
            color="red"
            onClickHandler={() => deleteClickHandler(cellProps.value)}
          />
        </>
      ),
    }),
  ]

  return (
    <Container paddingTop="50px" maxW={'150ch'}>
      <Stack spacing={10}>
        <Heading as="h1" size="xl">
          Forløb
        </Heading>
        <Box>
          <HStack justifyContent={'space-between'}>
            <CustomButton
              title="Opret forløb"
              onClickHandler={() => setCreateIsOpen(true)}
              color="green"
            />
            {!!lastUpdated && (
              <Text color="gray.500">
                Sidst opdateret: {new Date(lastUpdated).toLocaleString()}
              </Text>
            )}
          </HStack>
        </Box>
        {!!errorMessage ? (
          <Alert status="error">
            <AlertIcon />
            {errorMessage}
          </Alert>
        ) : status === 'loading' && !lastUpdated ? (
          <Spinner size="lg" />
        ) : (
          <CustomTable columns={columns} data={events} />
        )}
      </Stack>
      <CreateUpdateEventModal
        title="Opret forløb"
        content={`Forløb kan indeholde materialer. Grupper kan få adgang til forløb, således at gruppens brugere kan tilgå forløbets materialer i appen.`}
        isOpen={createIsOpen}
        eventId={null}
        onClose={() => setCreateIsOpen(false)}
      />
      <CreateUpdateEventModal
        title="Opdater forløb"
        content={`Dette har ingen effekt på de underliggende materialer.`}
        isOpen={updateIsOpen}
        eventId={eventToEdit?.id ?? null}
        onClose={() => setUpdateIsOpen(false)}
      />

      <AssignEventMaterialsModal
        title="Tildel materiale til forløb"
        content={`Materialer kan bruges af flere forskellige forløb.`}
        isOpen={assignMatsIsOpen}
        eventId={eventToEdit?.id ?? null}
        onClose={() => setAssignMatsIsOpen(false)}
      />
      <CustomAlertDialog
        title="Er du sikker på at du vil slette?"
        content={`Forløbet ${eventToEdit?.title} vil slettes permanent og kan ikke gendannes. Ingen tilhørende materialer vil slettes. Brugere mister adgang til materialerne næste gang de synkroniseres.`}
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onSubmit={deleteSubmitHandler}
      />
    </Container>
  )
}

export default Events
