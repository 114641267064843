export const appRoutes = {
	home: "/",
	accounts: "/brugere",
	groups: "/grupper",
	contents: "/tekster",
	materials: "/materialer",
	newsItems: "/nyheder",
	events: "/events",
	login: "/log-ind",
	privacyPolicy: "/privatlivspolitik",
	forgotLoginPassword: "/glemt-adgangskode",
	updateLoginPassword: "/opdater-adgangskode",
};