import { Alert, AlertIcon, Box, Stack } from '@chakra-ui/react'
import React, { FormEvent, useEffect, useState } from 'react'
import { createCCMindApiClient } from '../../api/ApiClientFactory'
import InputField, { InputFieldType } from './inputs/InputField'
import InputSubmitButton from './inputs/InputSubmitButton'

const ForgotLoginPasswordForm = () => {
  const [email, setEmail] = React.useState('')
  const [emailValidated, setEmailValidated] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')
  const [errorMode, setErrorMode] = useState(false)
  const [tempLockout, setTempLockout] = useState(false)

  useEffect(() => {
    if (tempLockout === false) return
    let timeout = setTimeout(() => setTempLockout(false), 3000)
    return () => clearTimeout(timeout)
  }, [tempLockout])

  const isValid = emailValidated
  const isEligible = !tempLockout && !isSubmitting

  let handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!isValid) {
      setErrorMode(true)
      return
    }

    setErrorMode(false)
    setErrorMessage("");
    setSuccessMessage("");
    setTempLockout(true)
    createCCMindApiClient()
      .accounts.resetAccountPassword({ email: email })
      .then(() => {
        setSuccessMessage(
          'Hvis email-adressen findes i vores system er der nu sendt en email med et link til oprettelse af ny adgangskode! Husk at tjekke din spam-folder.'
        )
        setEmail('')
      })
      .catch((error) =>
        setErrorMessage(
          'Noget gik galt. Prøv igen eller kontakt en administrator.'
        )
      )
      .finally(() => {
        setTempLockout(true)
        setIsSubmitting(false)
      })
  }

  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        <Stack spacing={3}>
          <InputField
            label="Email-adresse"
            name="email"
            type={InputFieldType.email}
            onChangeHandler={setEmail}
            onIsValidChangeHandler={setEmailValidated}
            value={email}
            placeholder="min-email@gmail.com"
            displayErrorIfInvalid={errorMode}
            isRequired={true}
          />

          <InputSubmitButton
            disabled={!isEligible}
            submitting={isSubmitting}
            title="Send link"
          />

          {errorMessage && (
            <Alert status="error">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}

          {successMessage && (
            <Alert status="success">
              <AlertIcon />
              {successMessage}
            </Alert>
          )}
        </Stack>
      </form>
    </Box>
  )
}

export default ForgotLoginPasswordForm
