/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignGroupEventsRequest } from '../models/AssignGroupEventsRequest';
import type { CreateGroupRequest } from '../models/CreateGroupRequest';
import type { GroupResponse } from '../models/GroupResponse';
import type { UpdateGroupRequest } from '../models/UpdateGroupRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class GroupsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Create a new group
     * @param requestBody 
     * @returns GroupResponse Created
     * @throws ApiError
     */
    public createGroup(
requestBody?: CreateGroupRequest,
): CancelablePromise<GroupResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Groups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Get groups
     * @returns GroupResponse Success
     * @throws ApiError
     */
    public getGroups(): CancelablePromise<Array<GroupResponse>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Groups',
        });
    }

    /**
     * Update an existing group
     * @param id 
     * @param requestBody 
     * @returns GroupResponse Created
     * @throws ApiError
     */
    public updateGroup(
id: number,
requestBody?: UpdateGroupRequest,
): CancelablePromise<GroupResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Groups/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>20101</i> - The group doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Get group
     * @param id 
     * @returns GroupResponse Success
     * @throws ApiError
     */
    public getGroup(
id: number,
): CancelablePromise<GroupResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>20101</i> - The group doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Delete group. Related accounts will also be deleted
     * @param id 
     * @returns void 
     * @throws ApiError
     */
    public deleteGroup(
id: number,
): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Groups/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `<p>Error codes:</p><ul><li><i>20101</i> - The group doesn't exist</li></ul>`,
            },
        });
    }

    /**
     * Assign events to a group.
     * This will replace any existing events for the group.
     * @param id 
     * @param requestBody 
     * @returns GroupResponse Success
     * @throws ApiError
     */
    public assignGroupEvents(
id: number,
requestBody?: AssignGroupEventsRequest,
): CancelablePromise<GroupResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/v1/Groups/{id}/assignEvents',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `<p>Error codes:</p><ul><li><i>20101</i> - The group doesn't exist</li></ul>`,
            },
        });
    }

}